<template>
  <div class="register-options">
    <div class="register-options__text">
      Варианты регистрации в Личном кабинете
    </div>

    <CustomButton> По номеру медицинской карты </CustomButton>

    <div class="register-options__remember">
      Я забыл(а) номер медицинской карты
    </div>

    <CustomButton white class="register-options__recovery"> Восстановить </CustomButton>

    <CustomButton bg-color="#F1842C" tag="router-link" to="new"> Я еще не посещал клинику </CustomButton>

<!--    <CustomButton white class="register-options__btn">-->
<!--      <div class="register-options__gos">-->
<!--        <img-->
<!--          src="@/assets/uploads/images/register/gos.svg"-->
<!--          alt="логотип гос услуг"-->
<!--        />-->
<!--        <p>Войти через Госуслуги</p>-->
<!--      </div>-->
<!--    </CustomButton>-->
  </div>
</template>

<script>
import CustomButton from '@/components/ui/buttons/CustomButton.vue'

export default {
  name: 'RegisterOptionsView',
  components: { CustomButton }
}
</script>

<style scoped lang="scss">
.register-options {
  &__text {
    text-align: center;
    margin-bottom: 25px;
    font-weight: 400;
    line-height: 100%;
    color: $blue;
  }

  &__remember {
    margin-bottom: 5px;
    text-align: center;
    font-weight: 600;
  }

  &__recovery {
    margin-bottom: 25px;
  }

  &__btn {
    padding: 0 12px;
  }

  &__gos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}
</style>
