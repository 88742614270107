<template>
  <div class="section-wrapper" :class="{
   'section-wrapper--stretched':  stretched
  }">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'SectionWrapper',
  props: {
    stretched: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 14px 14px 30px 14px;
  margin-bottom: 12px;
  @include desktop {
    background: none;
    padding: 0;
  }

  &--stretched {
    margin-bottom: 0;
    flex: 1 1 auto;
    @include desktop {
      position: relative;
    }
  }
}
</style>
