<template>
  <SectionWrapper stretched v-if="!user">
    <TheLoader/>
  </SectionWrapper>

  <MyAppointment v-if="user" :user="user"/>
</template>

<script>
import MyAppointment from '@/components/pages/my-schedule/MyAppointment.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import TheLoader from '@/components/ui/TheLoader.vue'

export default {
  name: 'MyScheduleView',
  components: { TheLoader, SectionWrapper, MyAppointment },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  }

}
</script>

<style scoped lang="scss">
</style>
