<template>
  <div class="members">
    <TheTitle textAlign="center">
      <h3>
        Личный кабинет пациента
      </h3>
    </TheTitle>

    <h4 class="members__subtitle">
      К данному номеру телефона привязано несколько карт, выберите пациента
    </h4>

    <ul class="members__list" v-if="listUserCards">
      <li class="members__item" v-for="card in listUserCards" :key="card.code">
        <CustomButton tag="button" type="button" marginBottom="5px" @click="signIn(card.code)">
          {{ card.name }}
        </CustomButton>
        <p class="members__id">
          № медкарты: {{ card.code }}
        </p>
      </li>
    </ul>

    <p class="help-block error" v-if="error">
      Произошла ошибка, попробуйте позже
    </p>
  </div>

</template>

<script>
import TheTitle from '@/components/ui/typography/TheTitle.vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { computed, inject, ref } from 'vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import axios from 'axios'
import { useAuthUser } from '@/composables/useAuthUser'
import { useRouter } from 'vue-router'
import { showFingerPrint } from '@/utilits/showFingerPrint'

export default {
  name: 'LoginMembersView',
  components: { CustomButton, TheTitle },
  setup () {
    const router = useRouter()
    const userStore = useUserStore()
    const { userCards, userSmsCode, userPhone, userToken } = storeToRefs(userStore)
    const toast = inject('toast')
    const error = ref(false)
    const showHelloToaster = () => {
      toast.success('Добро пожаловать!', {
        position: 'top-right',
        type: 'success'
      })
    }

    const listUserCards = computed(() => {
      return userCards.value ? userCards.value : null
    })

    const signIn = async (cardId) => {
      try {
        const response = await axios.post('/v2/element/user/auth-otp', {
          phone: userPhone.value,
          code: userSmsCode.value,
          card_id: cardId
        })

        if (response.data.status === 'ok') {
          error.value = false
          await useAuthUser(userPhone.value, userSmsCode.value)
          await router.push({ name: 'home' })
          showHelloToaster()
        } else {
          error.value = true
        }
      } catch (e) {
        error.value = true
      }

      if (userToken.value) {
        showFingerPrint(userToken.value)
      }
    }

    return {
      listUserCards,
      signIn,
      error
    }
  }
}
</script>

<style scoped lang="scss">
.members {
  &__subtitle {
    margin-bottom: 15px;
    color: $blue;
    font-weight: 350;
    line-height: 100%; /* 14px */
  }

  &__list {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

</style>
