<template>
  <div class="finance__item">
    <p class="finance__item-clinic">
      {{recipient.title}}
    </p>
    <p class="finance__item-legal">
      {{recipient.legal}}
    </p>
    <p class="finance__item-deposit">
      {{
        floatSum
          ? 'Задолженность: ' + floatSum
          : 'Задолженность отсутствует'
      }}
    </p>
    <p class="finance__item-about">
      {{recipient.about}}
    </p>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue'

export default {
  name: 'TheRecipient',
  props: {
    recipient: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup (props) {
    const { recipient } = toRefs(props)
    const floatSum = computed(() => {
      if (recipient.value.deposit > 0) {
        return new Intl.NumberFormat('ru-RU', {
          style: 'currency',
          currency: 'RUB'
        }).format(recipient.value.deposit / 100)
      } else {
        return recipient.value.deposit
      }
    })
    return {
      floatSum
    }
  }
}
</script>

<style scoped lang="scss">
.finance__item {
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.10);
  padding: 16px;
  transition: 0.2s;
  cursor: pointer;
  @include desktop {
    pointer-events: none;
  }
  &.active {
    background: #E1E8F4;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.10);
  }
}
.finance__item-clinic {
  color: #002856;
  font-size: 16px;
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 16px;
}
.finance__item-legal {
  color: #111;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 16px;
}

.finance__item-about {
  color: #7F8DA9;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}
.finance__item-deposit {
  color: #7F8DA9;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 16px;
}
</style>
