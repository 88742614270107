<template>
  <section class="login">
    <div class="container login__container">
      <div class="login__logo">
        <img src="@/assets/uploads/images/header/logo-blue.svg" alt="логотип к31">
      </div>

      <TheTitle>
        <h3>
          Вход в личный кабинет пациента
        </h3>
      </TheTitle>

      <form
          v-if="!loading"
          action=""
          class="login__form"
          @submit.prevent="loginUser"
          @keyup.enter.prevent="loginUser"

      >
        <div class="login__group" :class="{ 'form-group--error': v$.phone.$error || requestError }">
          <label for="login-phone">Телефон</label>
          <input
              v-model.trim="userInfo.phone"
              class="login__input"
              :class="{
                'validation-error': v$.phone.$error || requestError,
              }"
              name="login-phone"
              autocomplete="on"
          >
          <div v-if="v$.phone.$error || requestError" class="error help-block help-block-error">
            {{ errorMsg }}
          </div>
        </div>

        <div class="login__group" :class="{ 'form-group--error': v$.password.$error }">
          <label for="login-phone">Пароль</label>
          <input
              v-model.trim="userInfo.password"
              class="login__input"
              :class="{
                'validation-error': v$.password.$error,
              }"
              name="login-phone"
              type="password"
              autocomplete="on"
          >
          <div v-if="v$.password.$error || requestError" class="error help-block help-block-error">
            {{ errorMsg }}
          </div>
        </div>

        <a href="#" class="login__recovery-pass">
          Забыли пароль?
        </a>

        <a href="#" class="login__rules">
          Пользовательское соглашение
        </a>

        <CustomButton tag="button" type="submit">
          войти
        </CustomButton>

        <CustomButton white tag="router-link" to="/register/options">
          Регистрация
        </CustomButton>
      </form>

      <TheLoader
          v-if="loading"
      />
    </div>
  </section>
</template>

<script>
import TheTitle from '@/components/ui/typography/TheTitle.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { computed, inject, reactive, ref, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useRouter } from 'vue-router'
import { useAuthUser } from '@/composables/useAuthUser'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'loginView',
  components: { TheLoader, CustomButton, TheTitle },

  setup () {
    const userInfo = reactive({
      phone: null,
      password: null
    })
    const rules = {
      phone: { required },
      password: { required }
    }
    const v$ = useVuelidate(rules, userInfo)
    const requestError = ref(false)
    const loading = ref(false)
    const router = useRouter()
    const toast = inject('toast')
    const userStore = useUserStore()
    const { userToken } = storeToRefs(userStore)

    const showHelloToaster = () => {
      toast.success('Добро пожаловать!', {
        position: 'top-right',
        type: 'success'
      })
    }

    const errorMsg = computed(() => {
      if (requestError.value) {
        return 'Данные не верны'
      }
      return 'Необходимо заполнить поле.'
    })

    watch(userInfo, () => {
      requestError.value = false
    })
    const loginUser = async () => {
      v$.value.$touch()
      if (v$.value.$error) {
        return
      }
      loading.value = true

      try {
        await useAuthUser(userInfo.phone, userInfo.password)
        requestError.value = false
        await router.push({ name: 'home' })
        showHelloToaster()
      } catch (err) {
        requestError.value = true
        throw new Error(err)
      } finally {
        loading.value = false
      }

      if (userToken.value) {
        window.Fingerprint.registerBiometricSecret({
          description: 'Для работы с личным кабинетом надо пройти аутентификацию',
          secret: userToken.value,
          invalidateOnEnrollment: true,
          disableBackup: true // always disabled on Android
        })
      }
    }

    const onDeviceReady = () => {
      new Promise((resolve, reject) => {
        window.Fingerprint.isAvailable((suc) => {
          window.Fingerprint.loadBiometricSecret({
            description: 'Для работы с личным кабинетом надо пройти аутентификацию',
            disableBackup: true // always disabled on Android
          }, (suc) => {
            resolve(suc)
          }, (err) => {
            reject(err)
          })
        }, (err) => {
          reject(err)
        })
      }).then(suc => {
        router.push({
          name: 'home'
        })
      }).catch(() => {
        // router.push({
        //   name: 'login'
        // })
      })
    }
    onDeviceReady()

    return {
      loginUser,
      userInfo,
      v$,
      requestError,
      errorMsg,
      loading
    }
  }
}
</script>

<style scoped lang="scss">
.login {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 100vh;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    box-shadow: 1px 1px 2.5rem 1px rgba(0, 0, 0, .1);
    padding: 20px;
    border-radius: 20px;
    background: #FFFFFF;
  }

  &__logo {
    max-width: 100px;
  }

  &__form {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }

  &__group {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  &__recovery-pass {
    text-align: center;
  }

  &__input {
    border: unset;
    border-bottom: 1px solid #01468f;
  }

  &__rules {
    text-align: center;
  }
}

.help-block.error {
  color: #DF0000;
}

.form-group--error label {
  color: #DF0000;
}

.validation-error {
  border-color: #DF0000;
  color: #DF0000;
}

.validation-error::placeholder {
  color: #DF0000;
}

</style>
