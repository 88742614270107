<template>
  <div class="block-app">
    <div class="block-app__about">
      <div class="block-app__title">{{ data.json.title_social }}</div>
      <div class="block-app__text">{{ data.json.description_social }}</div>
    </div>
    <div class="block-app__list">
      <a :href="data.json.vk_url">
        <img src="@/assets/uploads/sprite/vk-upd.svg" alt="vk">
      </a>
      <a :href="data.json.tg_url">
        <img src="@/assets/uploads/sprite/telegram-upd.svg" alt="telegram">
      </a>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BlockApp',
  props: ['data']
}
</script>

<style lang="scss">
.block-app {
  padding: 12px 8px;
  border-radius: 6px;
  background: #E1E8F4;
}

.block-app__about {
  margin-bottom: 12px;
}

.block-app__title {
  color: #062E4F;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
}

.block-app__text {
  color: #54678D;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.block-app__list {
  display: flex;
  align-items: center;
  gap: 24px;
  a {
    display: block;
    img {
      width: 40px;
      height: 40px;
      filter: invert(40%) sepia(12%) saturate(1371%) hue-rotate(182deg) brightness(94%) contrast(90%);
    }
  }
}
</style>
