<template>
  <div v-show="isPhone" class="status-bar"></div>
  <TheHeader v-if="hiddenView" :user="user" :headerPadding="headerPadding"/>

  <main class="main" :class="{'main--small': isSmallMain, 'hidden': menuIsActive}">
    <div :class="{'main__container': hiddenView}">
      <TheMenu2 :show="true" @close="closeMenu" v-if="hiddenView">
      </TheMenu2>
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" :user="user"/>
        </keep-alive>
      </router-view>
    </div>
  </main>
  <TheFooter v-if="hiddenView" :footerPadding="footerPadding"/>
  <DesktopFooter v-if="hiddenView"/>
  <TheSidebar :user="user"/>
</template>

<script>
// глобальные стили для свайпера
import 'swiper/css'
import 'swiper/css/pagination'

import TheHeader from '@/components/layout/header/TheHeader.vue'
import TheFooter from '@/components/layout/footer/TheFooter.vue'
import TheMenu2 from '@/components/layout/menu/TheMenu2.vue'
import DesktopFooter from '@/components/layout/footer/DesktopFooter.vue'
import { useMenuStore } from '@/stores/MenuStore'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/UserStore'
import { computed, onMounted, ref } from 'vue'
import TheSidebar from '@/components/layout/sidebar/TheSidebar.vue'
import { useRoute } from 'vue-router'
import { authDevelopment } from '@/utilits/authDevelopment'
import { authBrowser } from '@/utilits/authBrowser'

export default {
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheMenu2,
    DesktopFooter
  },

  setup () {
    const menuStore = useMenuStore()
    const { closeMenu, toggleMenu } = menuStore
    const { menuIsActive } = storeToRefs(menuStore)
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    const route = useRoute()
    const specialRoute = ['login', 'finger', 'register-new', 'register-options', 'auth-phone', 'auth-members']
    const footerPadding = ref('')
    const headerPadding = ref('')

    const isSmallMain = computed(() => {
      return specialRoute.includes(route.name) || route.name === 'error'
    })

    const hiddenView = computed(() => {
      return !specialRoute.includes(route.name)
    })

    const isPhone = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform === 'ios' || platform === 'android'
    })

    onMounted(async () => {
      await authDevelopment()
      await authBrowser()

      if (isPhone.value) {
        const statusBar = document.querySelector('.status-bar')
        if (window?.device?.platform.toLowerCase() === 'android') {
          statusBar.style.height = '20px'
          footerPadding.value = '40px'
        } else if (window?.StatusBarHeight) {
          window.StatusBarHeight.getValue(
            function (value) {
              statusBar.style.height = value + 'px'
              headerPadding.value = value + 5 + 'px'
              if (value > 40) {
                footerPadding.value = '10px'
              }
            },
            function (error) {
              console.log(error)
              statusBar.style.height = '20px'
            }
          )
        }
      }
    })
    return {
      user,
      isSmallMain,
      hiddenView,
      menuIsActive,
      closeMenu,
      toggleMenu,
      footerPadding,
      headerPadding,
      isPhone
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/main.scss";
</style>

<style lang="scss">
body, html {
  height: 100vh
}

.container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.c-toast-container {
  margin-top: 40px;
}

body {
  background: #082B53;
  @include desktop {
    background: #EEF2F8;
  }
}

.status-bar {
  position: fixed;
  display: block;
  width: 100vw;
  height: 30px;
  left: 0px;
  top: 0px;
  padding-top: 20px;
  background: #082B53;
  z-index: 1000;
  @include desktop {
    display: none;
  }
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Circe', Circe, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #082B53;
  @include desktop {
    background: #EEF2F8;
  }
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-bottom: 50px;
  background: #082B53;
  overflow-y: hidden;
  z-index: 150;
  @include desktop {
    background: #EEF2F8;
    padding-top: 28px;
  }
}

.main--small {
  padding-bottom: 0;
}

.main:has( .filters__sidebar.active) {
  overflow: hidden;
  height: 100vh;
}

.main.hidden {
  overflow: hidden;
  height: 1000px;
  @include desktop {
    height: auto;
  }
}

.main__container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .modal-mask {
    display: none;
  }

  @include desktop {
    max-width: 1216px;
    padding: 0 15px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: minmax(320px, 380px) minmax(620px, 800px);
    column-gap: 20px;
    grid-auto-rows: max-content;
    .modal-mask {
      display: flex;
    }
  }
}
</style>
