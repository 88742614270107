<template>
  <li class="history__item">
    <a
        :href="loadLink"
        download="fileName"
        class="history__link"
        target="_blank"
    >
      <div class="history__top">
        <img src="@/assets/uploads/images/home-page/my-appointment/clock.svg" alt="картинка часов">
        <div class="history__time" v-if="timeAppointment">
          {{ timeAppointment }}
        </div>
        <div class="history__date">
          {{ dateAppointment }}
        </div>
        <div class="history__type">
          {{ type }}
        </div>
      </div>
      <div class="history__middle">
        <div class="history__picture">
          <img
              src="@/assets/uploads/images/med-card/analyzes.svg"
              alt="Капля крови"
          />
        </div>
        <div class="history__info">
          <p class="history__title">
            {{ title }}
          </p>
          <h4>
            {{ fileName }}
          </h4>
        </div>
      </div>
      <p class="history__desc" v-html="description">
      </p>
    </a>
  </li>
</template>

<script>
import { useDateStore } from '@/stores/date'
import { computed, toRefs } from 'vue'

export default {
  name: 'AnalyzesHistoryItem',
  props: {
    date: {
      type: String,
      require: true,
      default: null
    },
    type: {
      type: String,
      require: true,
      default: 'Анализы'
    },
    description: {
      type: String,
      require: true,
      default: 'Анализы'
    },
    title: {
      type: String,
      require: true,
      default: null
    },
    loadLink: {
      type: String,
      require: false,
      default: ''
    },
    fileName: {
      type: String,
      require: false,
      default: ''
    }
  },

  setup (props) {
    const { date } = toRefs(props)
    const dateStore = useDateStore()
    const dictMonthRod = dateStore.dictMonthRod

    const timeAppointment = computed(() => {
      if (!date.value) return null

      return date.value.split('T')[1].slice(0, 5)
    })

    const dateAppointment = computed(() => {
      if (!date.value) return null

      const [year, month, day] = date.value.split('T')[0].split('-')
      const formatMonth = month.startsWith(0) ? month[1] : month

      return `${day} ${dictMonthRod[parseInt(formatMonth) - 1]} ${year} г.`
    })

    return {
      timeAppointment,
      dateAppointment
    }
  }
}
</script>

<style scoped lang="scss">
.history {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
  color: #7F8DA9;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    padding: 8px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__time {
    margin-right: 10px;
    font-weight: 700;
    color: #222222;
  }

  &__date {
    margin-right: auto;
  }

  &__type {
    padding: 2px 8px;
    background: #F1842C;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
  }

  &__link {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__picture {
    width: 43px;
    height: 43px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #E1E8F4;

    & img {
    }
  }

  &__title {
    color: #002856;
    margin-bottom: 8px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__calendar {
    text-decoration-line: underline;
    color: #002856;
  }
}
</style>
