<template>
  <section class="section popular-services">
    <div class="container">
      <SectionWrapper>
        <SectionTitle title="Популярные сервисы" marginBottom="16px"></SectionTitle>

        <ul class="popular-services__list">
          <li class="popular-services__item">
            <router-link to="/online-appointment" class="popular-services__link">
              <IconInCircle bg-color="#FBE1CD" width="64" height="64">
                <img src="@/assets/uploads/images/home-page/popular-services/stethoscope.svg" alt="картинка стетоскопа">
              </IconInCircle>
              <div class="popular-services__text">
                <p>
                  Запись на прием
                </p>
              </div>
            </router-link>
          </li>

          <li class="popular-services__item">
            <router-link to="/finance" class="popular-services__link">
              <IconInCircle bg-color="#EFF4FD" width="64" height="64">
                <img src="@/assets/uploads/images/home-page/popular-services/wallet.svg" alt="картинка кошелька">
              </IconInCircle>
              <div class="popular-services__text">
                <p>
                  Мои
                  финансы
                </p>
              </div>
            </router-link>
          </li>

          <li class="popular-services__item">
            <router-link to="/agreement-appointment" class="popular-services__link">
              <IconInCircle bg-color="#E4FCE6" width="64" height="64">
                <img src="@/assets/uploads/images/home-page/popular-services/doctor.svg" alt="картинка кошелька">
              </IconInCircle>
              <div class="popular-services__text">
                <p>
                  Телемедицина
                </p>
              </div>
            </router-link>
          </li>

          <li class="popular-services__item">
            <router-link to="/call-doctor" class="popular-services__link">
              <IconInCircle bg-color="#F5E6FC" width="64" height="64">
                <img src="@/assets/uploads/images/home-page/popular-services/home.svg" alt="картинка кошелька">
              </IconInCircle>
              <div class="popular-services__text">
                <p>
                  Вызов врача на дом
                </p>
              </div>
            </router-link>
          </li>
        </ul>
      </SectionWrapper>

    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/ui/typography/SectionTitle.vue'
import IconInCircle from '@/components/ui/IconInCircle.vue'
import { inject } from 'vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'

export default {
  name: 'PopularServicesSection',
  components: { SectionWrapper, IconInCircle, SectionTitle },

  setup () {
    const toast = inject('toast')

    const showErrorToaster = () => {
      toast.error('Функционал в разработке', {
        position: 'top-right'
      })
    }

    return {
      showErrorToaster
    }
  }
}
</script>

<style scoped lang="scss">
.popular-services {
  position: relative;
  z-index: 1;

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
    justify-content: space-between;
    gap: 20px;
    @include desktop {
      gap: 16px;
      grid-template-columns: 1fr 1fr;
    }
    @include phone-xs {
      gap: 14px;
    }
    @media (min-width: 1200px){
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__item {
    @include desktop {
      border-radius: 8px;
      background: #FFF;
      padding: 16px 22px;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: $blue;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 15px;
    @include desktop {
      align-items: flex-start;
      gap: 8px;
    }
  }
}
</style>
