<template>
  <li class="appointment-list__item">
    <div class="appointment-list__top">
      <img
          src="@/assets/uploads/images/home-page/my-appointment/clock.svg"
          alt="картинка часов"
      />
      <div class="appointment-list__time" v-if="timeAppointment">
        {{ timeAppointment }}
      </div>
      <div class="appointment-list__date">
        {{ dateAppointment }}
      </div>
      <div class="appointment-list__type">Прием</div>
    </div>
    <a :href="calendarLink" class="appointment-list__calendar" target="_blank">
      Добавить в календарь
    </a>
    <div class="appointment-list__middle">
      <div class="appointment-list__picture">
        <img
            :src="
            'https://www.k31.ru/specialisty/photo/' +
            resourceId +
            '_295x295.jpg'
          "
            :alt="doctorName"
        />
      </div>
      <div class="appointment-list__info">
        <p class="appointment-list__department">
          {{ doctorDepartment }}
        </p>
        <h4 class="appointment-list__title">
          {{ doctorName }}
        </h4>
      </div>
    </div>
    <div class="appointment-list__bottom">
      <img
          src="@/assets/uploads/images/home-page/my-appointment/marker.svg"
          alt="знак метки на карте"
      />
      <p class="appointment-list__filial">К+31 на Лобачевского</p>
      <div class="appointment-list__delete" @click="openModal">
        Отмена записи
      </div>
    </div>

    <div>
      <CustomButton
          v-if="appointment?.zoom_url"
          target="_blank"
          :href="appointment?.payment_url"
      >
        Оплатить
      </CustomButton>

      <CustomButton
          v-if="appointment?.zoom_url"
          :href="appointment?.zoom_url"
          target="_blank"
          class="btn btn-primary"
          white
      >
        Войти
      </CustomButton>
    </div>
  </li>
  <ModalWrapper :modal-is-open="modalIsOpen" @modal-close="modalIsOpen = false">
    <div class="modal-appointment">
      <p class="modal-text">Вы точно хотите отменить запись?</p>
      <div class="appointment-list__item">
        <div class="appointment-list__top">
          <img
              src="@/assets/uploads/images/home-page/my-appointment/clock.svg"
              alt="картинка часов"
          />
          <div class="appointment-list__time" v-if="timeAppointment">
            {{ timeAppointment }}
          </div>
          <div class="appointment-list__date">
            {{ dateAppointment }}
          </div>
          <div class="appointment-list__type">Прием</div>
        </div>
        <a
            :href="calendarLink"
            class="appointment-list__calendar"
            target="_blank"
        >
          Добавить в календарь
        </a>
        <div class="appointment-list__middle">
          <div class="appointment-list__picture">
            <img
                :src="
                'https://www.k31.ru/specialisty/photo/' +
                resourceId +
                '_295x295.jpg'
              "
                :alt="doctorName"
            />
          </div>
          <div class="appointment-list__info">
            <p class="appointment-list__department">
              {{ doctorDepartment }}
            </p>
            <h4 class="appointment-list__title">
              {{ doctorName }}
            </h4>
          </div>
        </div>
        <div class="appointment-list__bottom">
          <img
              src="@/assets/uploads/images/home-page/my-appointment/marker.svg"
              alt="знак метки на карте"
          />
          <p class="appointment-list__filial">К+31 на Лобачевского</p>
          <div class="appointment-list__delete" @click="openModal">
            Отмена записи
          </div>
        </div>
      </div>
      <button @click.prevent="deleteAppointment" class="modal-btn">
        Отменить
      </button>
    </div>
  </ModalWrapper>
</template>

<script>
import { useDateStore } from '@/stores/date'
import { computed, ref, toRefs } from 'vue'
import ModalWrapper from '@/components/Common/ModalWrapper.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'

export default {
  name: 'AppointmentListItem',
  components: { CustomButton, ModalWrapper },
  emits: ['deleteAppointment'],
  props: {
    appointment: {
      type: Object,
      require: true,
      default: () => ({})
    },
    date: {
      type: String,
      require: true,
      default: null
    },
    doctorInfo: {
      type: String,
      require: true,
      default: null
    },
    calendarLink: {
      type: String,
      require: true,
      default: null
    },
    resourceId: {
      type: Number,
      require: true,
      default: null
    }
  },

  setup (props, { emit }) {
    const { date, doctorInfo, resourceId } = toRefs(props)
    const dateStore = useDateStore()
    const dictMonthRod = dateStore.dictMonthRod
    const modalIsOpen = ref(false)
    const timeAppointment = computed(() => {
      if (!date.value) return null

      return date.value.split('T')[1].slice(0, 5)
    })

    const dateAppointment = computed(() => {
      if (!date.value) return null

      const [year, month, day] = date.value.split('T')[0].split('-')
      const formatMonth = month.startsWith(0) ? month[1] : month

      return `${day} ${dictMonthRod[parseInt(formatMonth) - 1]} ${year} г.`
    })

    const doctorName = computed(() => {
      if (!doctorInfo.value) return null

      return doctorInfo.value.split('.')[0]
    })

    const doctorDepartment = computed(() => {
      if (!doctorInfo.value) return null

      return doctorInfo.value.split('.').slice(1).join(' ')
    })
    const deleteAppointment = () => {
      if (resourceId) {
        emit('deleteAppointment', resourceId)
        modalIsOpen.value = false
      }
    }
    const openModal = () => {
      modalIsOpen.value = true
      window.scrollTo(0, 0)
    }
    return {
      timeAppointment,
      dateAppointment,
      doctorName,
      doctorDepartment,
      deleteAppointment,
      modalIsOpen,
      openModal
    }
  }
}
</script>

<style scoped lang="scss">
.modal-text {
  margin-bottom: 10px;
}

.modal-btn {
  color: #062e4f;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  border-radius: 30px;
  background: #bac7de;
  padding: 10px;
  width: 100%;
  border: none;
  margin-top: 32px;
}

.appointment-list__delete {
  cursor: pointer;
  color: #002856;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  text-decoration-line: underline;
}

.appointment-list {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
  color: #7f8da9;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__time {
    margin-right: 10px;
    font-weight: 700;
    color: #222222;
  }

  &__date {
    margin-right: auto;
  }

  &__type {
    padding: 2px 8px;
    background: #002856;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__picture {
    max-width: 45px;

    & img {
      border-radius: 50%;
    }
  }

  &__info {
  }

  &__department {
    margin-bottom: 4px;
  }

  &__title {
    color: #002856;
    text-align: left;
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filial {
    margin-right: auto;
  }

  &__calendar {
    text-decoration-line: underline;
    color: #002856;
    text-align: left;
  }
}
</style>
