<template>
  <ArrowTitle title="Мои файлы"></ArrowTitle>
  <SectionWrapper stretched>
    <div class="files__grid" v-if="!loading">
      <div class="files__loader">
        <div class="file__img">
          <img src="@/assets/uploads/images/files/load.svg" alt="">
        </div>
        <p>
          Загрузить новый файл
        </p>
      </div>
      <template v-for="item in filesList" :key="item.id">
        <FileItem :file="item"></FileItem>
      </template>
    </div>
    <TheLoader v-if="loading"> Загрузка</TheLoader>
  </SectionWrapper>
</template>

<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import FileItem from '@/components/pages/files/FileItem.vue'
import { computed, ref, watch } from 'vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'FilesView',
  components: { TheLoader, ArrowTitle, SectionWrapper, FileItem },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup () {
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const loading = ref(false)
    const openLoader = ref(false)
    const fileData = ref(null)
    const selectedFile = ref(null)
    const openFileModal = ref(false)
    const closeLoader = (value) => {
      openLoader.value = false
    }
    const closeModal = (value) => {
      openFileModal.value = false
    }
    const selectFile = (file) => {
      selectedFile.value = file
      openFileModal.value = true
    }
    const getUserFiles = async (id) => {
      try {
        loading.value = true

        const response = await axios.get(
            `v1/user-file?user_id=${id}`,
            {
              headers: {
                Authorization: `Bearer ${userToken.value}`
              }
            }
        )

        if (response.data.status === 'ok') {
          return response.data.data
        } else {
          return null
        }
      } catch (err) {
        console.log(err)
      } finally {
        loading.value = false
      }
    }
    const filesList = computed(() => {
      if (fileData.value) {
        return fileData.value.files
      }

      return null
    })

    const doctorsList = computed(() => {
      if (fileData.value) {
        return fileData.value.doctors
      }

      return null
    })
    watch(() => getUserId.value, async () => {
      fileData.value = await getUserFiles(getUserId.value)
    }, {
      immediate: true
    })
    return {
      filesList,
      loading,
      openLoader,
      closeLoader,
      closeModal,
      getUserFiles,
      doctorsList,
      selectFile,
      selectedFile,
      openFileModal
    }
  }
}
</script>

<style scoped lang="scss">
.files__grid {
  display: grid;
  grid-template-columns: repeat(3, 108px);
  gap: 12px;
}

.file__img {
  border-radius: 6px;
  background: #E1E8F4;
  width: 108px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.files__loader {
  opacity: 0.6;

  p {
    color: #002856;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    margin-top: 4px;
  }
}
</style>
