<template>
  <div class="icon-in-circle" :style="{ 'background-color': bgColor, 'width': width + 'px', 'height': height + 'px' }" >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'IconInCircle',
  props: {
    bgColor: {
      type: String,
      required: true,
      default: '#fff'
    },
    width: {
      type: String || Number,
      required: false,
      default: null
    },
    height: {
      type: String || Number,
      required: false,
      default: null
    }
  }

}
</script>

<style scoped lang="scss">
.icon-in-circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
