import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useMenuStore } from '@/stores/MenuStore'
import { getCookie } from '@/utilits/getCookie'
import { deleteCookieToken } from '@/utilits/deleteCookieToken'
import { getFirebaseToken } from '@/utilits/getFirebaseToken'

export const useUserStore = defineStore('user', () => {
  const user = ref(null)
  const userToken = ref(null)
  const userCards = ref(null)
  const userSmsCode = ref(null)
  const userPhone = ref(null)
  const router = useRouter()
  const menuStore = useMenuStore()
  const { closeMenu } = menuStore

  const userTokenStorage = computed(() => {
    return getCookie('user_token')
  })

  const getUserId = computed(() => {
    if (!user.value) return null

    return user.value.id
  })

  const changeUserToFamilyMember = (member) => {
    for (const memberKey in member) {
      user.value[memberKey] = member[memberKey]
    }
  }

  const userFullName = computed(() => {
    if (!user.value) return null

    return `
    ${user.value.last_name}
    ${user.value.first_name}
    ${user.value.middle_name}
    `
  })
  const setUserCards = (cards) => {
    userCards.value = cards
  }
  const setUserSmsCode = (code) => {
    // [{code:, name:},{code:, name:}]
    userSmsCode.value = code
  }

  const setUserPhone = (phone) => {
    userPhone.value = phone
  }

  const setUserToken = (token) => {
    const twoWeeksInMs = 3600 * 24 * 14
    document.cookie = `user_token=${token}; max-age=${twoWeeksInMs}; path=/; secure`
    window.localStorage.setItem('user_token', token)
    userToken.value = token
  }

  const logOut = () => {
    deleteCookieToken()
    userToken.value = null
    user.value = null
    closeMenu()
    router.push({
      name: 'auth-phone'
    })
  }

  const setUser = async (token) => {
    try {
      const response = await axios.get('/v1/user/index', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (response.data.status === 'ok') {
        user.value = { ...response.data.data }
        user.value.family_cards.unshift({ ...response.data.data })

        await getFirebaseToken()
      }
    } catch (err) {
      throw new Error(err)
    }
  }

  const setUserAvatar = (newPhoto) => {
    user.value.photo = newPhoto
  }

  return {
    user,
    setUserToken,
    userToken,
    setUser,
    userTokenStorage,
    logOut,
    getUserId,
    setUserCards,
    setUserSmsCode,
    userCards,
    userSmsCode,
    setUserPhone,
    userPhone,
    userFullName,
    setUserAvatar,
    changeUserToFamilyMember
  }
})
