<template>
  <div class="search">
    <input class="search__input" type="text" :placeholder="placeholder" @input="updateValue($event)">
  </div>
</template>

<script>

import { ref } from 'vue'

export default {
  name: 'TheSearch',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Доктора, отделения, услуги'
    }
  },
  setup (props, { emit }) {
    const model = ref('')
    const updateValue = (value) => {
      emit('input', value)
    }
    return {
      updateValue,
      model
    }
  }
}
</script>

<style scoped lang="scss">
.search {

  &__input {
    padding: 16px 25px 16px 50px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #BAC7DE;
    border-radius: 8px;
    background-image: url('~@/assets/uploads/images/header/search.svg');
    background-repeat: no-repeat;
    background-position: 15px center;

    &::placeholder {
      font-weight: 350;
      font-size: 16px;
      line-height: 100%;
      color: #062E4F;
    }
  }
}
</style>
