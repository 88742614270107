<template>
  <header class="header" :style="{paddingTop: headerPadding}">
    <div class="container header__container">
      <div class="header__col header__col--left">
        <router-link to="/" class="header__logo" @click="closeMenu">
          <img src="@/assets/uploads/images/header/logo.svg" alt="логотип к31">
        </router-link>
        <router-link to="/" class="header__logo header__logo--desktop" @click="closeMenu">
          <img src="@/assets/uploads/images/header/logo-blue.svg" alt="логотип к31">
        </router-link>
      </div>
      <div class="header__col header__col--right">
        <div v-if="!user" class="header__login">
          <div class="header__login-link" @click="closeMenu">
            <router-link to="/auth/phone">
              вход/регистрация
            </router-link>
          </div>
        </div>
        <a href="tel:+74999993131" class="header__tel">
          <IconInCircle bg-color="#E1E8F4" width="32" height="32">
            <img src="@/assets/uploads/images/header/phone.svg" alt="иконка телефона">
          </IconInCircle>
          <span>+7 (499) 999-31-31</span>
        </a>
        <NotificationBell/>
      </div>
    </div>
  </header>
  <div class="announce" v-if="announceVisible">
    <div class="announce__container container">
      <div class="announce__wrapper">
        <div class="announce__top">
          <img src="@/assets/uploads/icon/common/alert.svg" alt="восклицательный знак">
          <a v-if="announce.link" :href="announce.link" target="_blank" class="announce__link">
              <span class="text">
                  {{ announce.description }}
              </span>
          </a>

          <p v-else class="announce__text">
            {{ announce.description }}
          </p>
        </div>

        <button @click="announceVisible = false" class="announce__close" type="button">
          <img src="@/assets/uploads/icon/common/close-alert.svg" alt="знак закрытия">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconInCircle from '@/components/ui/IconInCircle.vue'
import NotificationBell from '@/components/layout/header/NotificationBell.vue'
import { computed, inject, onMounted, reactive, ref, toRefs } from 'vue'
import { useMenuStore } from '@/stores/MenuStore'
import axios from 'axios'

export default {
  name: 'TheHeader',
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    headerPadding: {
      type: String,
      required: false,
      default: '40px'
    }
  },
  components: { NotificationBell, IconInCircle },

  setup (props) {
    const menuStore = useMenuStore()
    const { closeMenu } = menuStore
    const { user } = toRefs(props)
    const toast = inject('toast')
    const announceVisible = ref(false)
    const announce = reactive({
      link: null,
      description: 'asddsadsasdasda asdasd adsdasasd asdasddsa'
    })

    const showErrorToaster = () => {
      toast.error('Функционал в разработке', {
        position: 'top-right'
      })
    }

    const userFullName = computed(() => {
      if (user.value) {
        const middleName = user.value.middle_name
        return `
        ${user.value.last_name}
        ${user.value.first_name[0].toUpperCase()}.
        ${middleName ? middleName[0].toUpperCase() : ''}.`
      }

      return ''
    })

    const getAnnounce = async () => {
      try {
        const responce = await axios('/v1/announce')
        announceVisible.value = true

        if (responce.data.status === 'ok') {
          announce.link = responce.data.data[0].link
          announce.description = responce.data.data[0].description
        } else {
          announce.link = null
          announce.description = null
          announceVisible.value = false
        }
      } catch (e) {
        announceVisible.value = false
        announce.link = null
        announce.description = null
      }
    }

    onMounted(async () => {
      await getAnnounce()
    })

    return {
      userFullName,
      showErrorToaster,
      closeMenu,
      announce,
      announceVisible
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  padding-bottom: 20px;
  padding-top: 40px;
  background: #082B53;
  //position: fixed;
  //top: 0;
  //width: 100%;
  //z-index: 200;
  @include desktop {
    background: #fff;
    padding: 20px 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  //&__top {
  //  display: flex;
  //  align-items: center;
  //  gap: 20px;
  //}

  &__logo {
    @include desktop {
      display: none;
    }
  }

  &__person {
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__icon {
    flex: 1 0 auto;
  }

  &__bottom {
    max-width: 345px;
    @include desktop {
      display: none;
    }
  }
}

.header__logo--desktop {
  display: none;
  @include desktop {
    display: block;
    img {
      width: 85px;
    }
  }
}

.header__tel {
  align-items: center;
  display: flex;

  img {
    filter: invert(9%) sepia(79%) saturate(2780%) hue-rotate(200deg) brightness(93%) contrast(102%);
  }

  span {
    display: none;
    color: $blue;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 12px;
    @include desktop {
      display: block;
    }
  }
}

.header__login a {
  border-radius: 30px;
  background: #BAC7DE;
  color: #002856;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  padding: 10px 11px;
  white-space: nowrap;
  transition: 0.2s;

  &:hover {
    background: #E1E8F4;
  }

  @include desktop {
    font-size: 14px;
    padding: 12px 20px;
  }
}

.header__col {
  display: flex;
  align-items: center;

  &--left {
    gap: 25px;
  }

  &--right {
    gap: 18px;
    @include desktop {
      gap: 32px;
    }
  }
}

.announce {
  margin-top: 10px;
  margin-bottom: 10px;
}

.announce__container {

}

.announce__link {
  text-decoration: underline;
}

.announce__wrapper {
  border-radius: 8px;
  background: $orange;
  gap: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

.announce__link {
}

.announce__top {
  display: flex;
  align-items: start;
  flex: 1;
  gap: 10px;
}

.announce__text {
  color: #062E4F;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.announce__close {
  padding: 5px;
  align-self: start;
  border: unset;
}
</style>
