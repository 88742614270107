<template>
  <div class="radio-wrapper">
    <label class="wrapper flex items-center">
      <span :style="{color: color}">
        {{ label }}
      </span>
      <input
          :name="name"
          class="checkbox"
          type="radio"
          :checked="isChecked"
          :value="value"
          @change="$emit('update:modelValue', +$event.target.value)"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue'

export default {
  name: 'FormRadio',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: '',
      required: true
    },
    modelValue: {
      default: ''
    },
    value: {
      type: Number,
      default: undefined
    },
    name: {
      type: String,
      default: 'drone',
      required: false
    },
    color: {
      type: String,
      default: '',
      required: false
    }
  },

  setup (props) {
    const { modelValue, value } = toRefs(props)

    const isChecked = computed(() => {
      return modelValue.value === value.value
    })

    return {
      isChecked
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
}

/* Hide the browser's default radio button */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #eee;
  border: 1px solid #BAC7DE;
}

/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.wrapper input:checked ~ .checkmark {
  background: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.wrapper .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #002856;
}
span {
  font-size: 16px;
  font-weight: 700;
}
</style>
