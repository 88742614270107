<template>
  <div class="block-offer-main">
    <div class="block-offer-main__title" v-if="data.json.title" v-html="data.json.title"></div>
    <div class="block-offer-main__text" v-if="data.json.preview" v-html="data.json.preview"></div>
    <div class="block-offer-main__img">
      <img :src="data.json.img.desktop" alt=""/>
    </div>
    <div class="block-offer-main__link" v-if="user">
      <CustomButton @click="$emit('scroll')" type="button" tag="button" bgColor="#F1842C">
        {{ data.json.btn.title }}
      </CustomButton>
    </div>
  </div>
</template>
<script>

import CustomButton from '@/components/ui/buttons/CustomButton.vue'

export default {
  name: 'BlockOfferMain',
  components: { CustomButton },
  props: ['data', 'alias', 'user']
}
</script>

<style lang="scss">
.block-offer-main {
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 14px 12px;
}

.block-offer-main__img {
  margin-top: 8px;

  img {
    @include desktop {
      max-width: 480px;
    }
  }
}

.block-offer-main__title {
  color: #062E4F;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 5px;
  @include desktop {
    font-size: 22px;
    margin-bottom: 16px;
  }
}

.block-offer-main__link {
  margin-top: 16px;
}

.block-offer-main__text {
  color: #54678D;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  @include desktop {
    font-size: 16px;
  }

  ul {
    margin-left: 20px;
    margin-bottom: 8px;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  ul li {
    list-style: disc;
    overflow: visible;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
</style>
