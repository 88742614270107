<template>
  <div class="checkbox" :class="{disabled: disabled, white: white}">
    <div class="checkbox__rules">
      <input
          :disabled="disabled"
          class="checkbox-input"
          :checked="modelValue"
          type="checkbox"
          :id="id"
          :value="modelValue"
          @change="$emit('update:modelValue', $event.target.checked )"
      />
      <label :for="id" class="checkbox__label">
        <span>
          {{ label }}
        </span>
      </label>
      <a v-if="href" @click="stopEventIfDisabled" target="_blank" :href="href">
        {{ linkLabel }}
      </a>
    </div>
    <div v-if="validError" class="error help-block help-block-error">
      Необходимо дать согласие
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue'

export default {
  name: 'FormInputCheckbox',
  props: {
    id: {
      type: String,
      required: true,
      defualt: ''
    },
    modelValue: {
      type: Boolean,
      required: true,
      default: false
    },
    validError: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: true,
      default: ''
    },
    linkLabel: {
      type: String,
      required: false,
      default: ''
    },
    href: {
      type: String,
      required: false,
      default: ''
    },
    white: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup (props) {
    const { disabled } = toRefs(props)
    const stopEventIfDisabled = (event) => {
      if (disabled.value) {
        event.preventDefault()
      }
    }

    return {
      stopEventIfDisabled
    }
  }
}
</script>

<style scoped lang="scss">
.checkbox {
  &__rules {
    color: black;
    margin-bottom: 8px;
    line-height: 16px;
  }
}

.checkbox.white .checkbox__rules {
  color: white;
}

.checkbox a {
  color: $blue;
  text-decoration: underline;
}

.checkbox.white a {
  color: white;
  text-decoration: underline;
}

.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  position: relative;
  cursor: pointer;
  margin-right: 5px;
}

.checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid #808080;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.checkbox.white label:before {
  background-color: white;
}

.checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  left: 5px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid #002856;
  border-radius: 1px;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkbox.disabled {

  & a {
    color: #BAC7DE;
  }

  & label {
    color: #BAC7DE;
  }

  color: #BAC7DE;
}

.checkbox.disabled input:checked + label:after {
  border: solid #BAC7DE;
  border-radius: 1px;
  border-width: 0 3px 3px 0;
}
</style>
