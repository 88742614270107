<template>
  <div class="modal-payment" :class="{ 'active' : paymentData.success}">
    <div class="container">
      <div class="modal-payment__title">
        <div class="section-title__back" @click="close">
          <img
            src="@/assets/uploads/icon/common/arrow-back.svg"
            alt="стрелка">
        </div>
        <h3>Подтверждение оплаты</h3>
      </div>
      <div class="modal-payment__content">
        <img src="@/assets/uploads/images/finance.svg" alt="">
        <p>
          Пополнение {{ paymentData.clinic }} на сумму {{ paymentData.payment }} руб.
        </p>
        <a :href="paymentData.link" target="_blank" class="btn">Оплатить</a>
      </div>
    </div>
  </div>
</template>

<script>

import { toRefs } from 'vue'

export default {
  name: 'ModalPayment',
  emits: ['modalClose'],
  props: {
    paymentData: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup (props, { emit }) {
    const { paymentData } = toRefs(props)
    const close = () => {
      paymentData.value.success = false
      emit('modalClose', false)
    }
    return {
      close
    }
  }
}
</script>

<style scoped lang="scss">

.modal-payment {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  padding: 12px 0;
  transition: all .5s ease-in-out;
  opacity: 0;

  &.active {
    top: 0;
    opacity: 1;
  }
}
.modal-payment__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-bottom: v-bind('mb');

  & h1, h2, h3, h4, h5, h6 {
    color: #082B53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  img {
    filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg) brightness(102%) contrast(103%);
  }
}

.modal-payment__content {
  text-align: center;
  img {
    margin-bottom: 32px;
  }
  a {
    color: #062E4F;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    border-radius: 30px;
    background: var(--blue-1, #BAC7DE);
    padding: 11px;
    width: 100%;
    border: none;
    margin-top: 16px;
  }
  p {
    color: #002856;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  padding: 0 15px;
  margin-top: 24px;

}
</style>
