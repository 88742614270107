export const showFingerPrint = (token) => {
  window?.Fingerprint?.registerBiometricSecret(
    {
      description: 'Для работы с личным кабинетом надо пройти аутентификацию',
      secret: token,
      invalidateOnEnrollment: true,
      disableBackup: true // always disabled on Android
    },
    successCallback,
    errorCallback
  )

  function successCallback () {
    // alert('Authentication successful')
  }

  function errorCallback () {
    // alert('Authentication invalid ' + error.message)
  }
}
