<template>
  <p class="the-text">
    <slot/>
  </p>
</template>

<script>
export default {
  name: 'TheText',
  props: {
    color: {
      type: String,
      default: '#082B53',
      required: false
    },
    marginBottom: {
      type: String,
      default: '5px',
      required: false
    },
    textAlign: {
      type: String,
      default: 'start',
      required: false
    },
    fontSize: {
      type: String,
      default: '14px',
      required: false
    }
  }
}
</script>

<style scoped>
.the-text {
  margin-bottom: v-bind('marginBottom');
  color: v-bind('color');
  font-family: Circe;
  font-size: v-bind('fontSize');
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-align: v-bind('textAlign');
}
</style>
