<template>
  <div class="section-title" :class="{'mobile--white': mobileWhite}">
    <h3>{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    mobileWhite: {
      type: Boolean,
      default: false,
      required: false
    },
    marginBottom: {
      type: String,
      default: '10px',
      required: false
    }
  }
}
</script>

<style lang="scss">
.section-title {
  & h1, h2, h3, h4, h5, h6 {
    color: #002856;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: v-bind('marginBottom');
    @include desktop {
      font-size: 22px;
      color: #002856;
    }
  }

  &.mobile--white {
    & h1, h2, h3, h4, h5, h6 {
      color: #FFF;
      @include desktop {
        color: #002856;
      }
    }
  }
}
</style>
