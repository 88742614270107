<template>
  <ArrowTitle title="Вакцинация"></ArrowTitle>
  <SectionWrapper stretched>
    <div class="calendar" v-if="!loading">
      <div class="calendar__vaccination-years" v-if="years.length > 0">
        <ul class="tags">
          <li
              v-for="year in years"
              :key="year.id"
              @click="currentTab = year"
              :class="{ active: currentTab === year }"
          >
            {{ year }}
          </li>
        </ul>
      </div>
      <div class="calendar__vaccination-list">
        <VaccinationItem v-for="(item, index) in dataForYear" :key="index" :vaccine="item"
                         @click="getSelectedData(index)"></VaccinationItem>
      </div>
    </div>
    <ModalWrapper :modal-is-open="modalIsOpen" @modal-close="modalIsOpen = false" title="Вакцинация">
      <div v-if="selectedItem">
        {{ selectedItem.namegrp }}
      </div>
    </ModalWrapper>
    <TheText v-if="loading && !vaccinesList">
      Здесь буду отображаться ваши данные после прохождения вакцинации
    </TheText>

    <TheLoader v-if="loading"/>
  </SectionWrapper>
</template>

<script>

import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import VaccinationItem from '@/components/pages/calendar/VaccinationItem.vue'
import ModalWrapper from '@/components/Common/ModalWrapper.vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import axios from 'axios'
import TheLoader from '@/components/ui/TheLoader.vue'
import TheText from '@/components/ui/typography/TheText.vue'

export default {
  name: 'VaccinationCalendarView',
  components: { TheText, TheLoader, SectionWrapper, ArrowTitle, VaccinationItem, ModalWrapper },
  setup () {
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const vaccinesList = ref(null)
    const loading = ref(false)
    const years = ref([])
    const currentTab = ref('')
    const modalIsOpen = ref(false)
    const selectedItem = ref(null)
    const getSelectedData = (id) => {
      modalIsOpen.value = true
      selectedItem.value = dataForYear.value[id]
    }
    const vaccinesUserList = computed(() => {
      if (vaccinesList.value && vaccinesList.value.length > 0) {
        return vaccinesList.value
      }

      return null
    })

    const getUserVaccines = async (id) => {
      try {
        loading.value = true

        const response = await axios.get(
            `/v1/events/vaccination/?user_id=${id}`,
            {
              headers: {
                Authorization: `Bearer ${userToken.value}`
              }
            }
        )

        if (response.data.status === 'ok') {
          years.value = Object.keys(response.data.data)
          return response.data.data
        } else {
          return null
        }
      } catch (err) {
        console.log(err)
      } finally {
        loading.value = false
      }
    }
    const dataForYear = computed(() => {
      if (vaccinesList.value) {
        return vaccinesList.value[currentTab.value]
      }
      return null
    })

    watch(() => getUserId.value, async () => {
      if (getUserId.value) {
        vaccinesList.value = await getUserVaccines(getUserId.value)
      }
    },
    {
      immediate: true
    })

    return {
      vaccinesUserList,
      vaccinesList,
      loading,
      getUserVaccines,
      years,
      dataForYear,
      currentTab,
      modalIsOpen,
      selectedItem,
      getSelectedData
    }
  }
}
</script>

<style scoped lang="scss">
.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  li {
    color: #062E4F;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    border-radius: 30px;
    background: #E1E8F4;
    padding: 8px 16px;
    cursor: pointer;

    &.active {
      background: #002856;
      color: #fff;
    }
  }
}

.calendar__vaccination-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}
</style>
