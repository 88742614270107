<template>
  <div class="file__item">
    <div class="file__img">
      <img src="@/assets/uploads/images/files/doc.svg" alt="" v-if="file.type==='jpg'">
      <img :src="file.url" :alt="file.orig_name">
      <img src="@/assets/uploads/images/files/pdf.svg" alt="" v-if="file.type==='pdf'">
    </div>
    <div class="file__name">
      {{ file.orig_name }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'FileItem',
  props: {
    file: {
      type: [Object, null],
      required: true,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.file__name {
  color: #002856;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 6px;
}

.file__img {
  border-radius: 6px;
  background: #E1E8F4;
  width: 108px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    object-fit: cover;
  }
}
</style>
