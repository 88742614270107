<template>
  <ArrowTitle title="Финансы"></ArrowTitle>
  <SectionWrapper stretched>
    <div class="finance__list" v-if="!loading">
      <Recipient v-for="item in finance" :key="item.id" :recipient="item"
                 @click="changeCurrentId(item.id)"
                 :class="{'active': currentId === item.id}"></Recipient>
    </div>
    <div class="finance__control" v-if="!loading">
      <div class="finance__control-label">Аванс (руб.)</div>
      <input
          type="number"
          class="finance__payment"
          name="finance-payment"
          v-model="paymentInfo.payment"
          placeholder="Впишите сумму"
          ref="sumInput"
      />
      <button class="finance__btn" @click.prevent="submitPayment">
        Внести аванс
      </button>
      <div ref="bottom"></div>
      <div class="error" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
    <ModalPayment :paymentData="paymentInfo" @modalClose="clearInput"></ModalPayment>
    <TheLoader v-if="loading"> Загрузка</TheLoader>
  </SectionWrapper>
</template>

<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import Recipient from '@/components/pages/finance/Recipient.vue'
import ModalPayment from '@/components/pages/finance/ModalPayment.vue'
import { computed, reactive, ref, watch } from 'vue'
import axios from 'axios'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/UserStore'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useVuelidate } from '@vuelidate/core'
import { minValue, required } from '@vuelidate/validators'

export default {
  name: 'FinanceView',
  components: { TheLoader, ArrowTitle, SectionWrapper, Recipient, ModalPayment },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup () {
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const finance = ref(null)
    const currentId = ref(null)
    const paymentLink = ref('')
    const sumInput = ref(null)
    const bottom = ref(null)
    const loading = ref(false)
    const paymentInfo = reactive({
      payment: '',
      success: false,
      link: '',
      clinic: ''
    })
    const clearInput = (value) => {
      paymentInfo.payment = ''
      paymentInfo.success = false
      paymentInfo.link = ''
      paymentInfo.clinic = ''
      currentId.value = ref(null)
    }
    const errorMessage = ref('')
    const rules = {
      payment: {
        required,
        minValue: minValue(50)
      }
    }
    const currentFinance = computed(() => {
      return finance.value.find((el) => el.id === currentId.value)
    })

    const v$ = useVuelidate(rules, paymentInfo)
    const fetchDeposit = async (id) => {
      try {
        loading.value = true
        const response = await axios.get(`/v1/element/deposit/?user_id=${id}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          finance.value = response.data.data
        } else {
          return null
        }
      } catch (err) {
        throw new Error()
      } finally {
        loading.value = false
      }
    }

    const submitPayment = async () => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        if (v$.value.payment.required.$invalid) {
          errorMessage.value = 'Необходимо указать сумму'
        } else if (v$.value.payment.minValue.$invalid) {
          errorMessage.value = 'Минимальная сумма оплаты: 50 рублей'
        }
      } else {
        try {
          const response = await axios.post(
              `v1/pay/element/?user_id=${getUserId.value}`, {
                legal_entity_id: currentFinance.value.id,
                summ: paymentInfo.payment * 100
              },
              {
                headers: {
                  Accept: '*/*',
                  Authorization: `Bearer ${userToken.value}`,
                  'Content-Type': 'application/json',
                  'Cache-Control': 'no-cache'
                }
              }
          )
          if (response.data.status === 'ok') {
            window.scrollTo(0, 0)
            paymentInfo.link = response.data.data
            paymentInfo.success = true
            paymentInfo.clinic = currentFinance.value.title
          } else {
            return null
          }
        } catch (e) {
          console.log(e)
        }
      }
    }

    const changeCurrentId = (id) => {
      currentId.value = id
      bottom.value.scrollIntoView(false)
    }

    watch(() => getUserId.value, async () => {
      if (getUserId.value) {
        await fetchDeposit(getUserId.value)
      }
    }, {
      immediate: true
    })

    return {
      finance,
      loading,
      paymentInfo,
      currentId,
      v$,
      submitPayment,
      errorMessage,
      currentFinance,
      paymentLink,
      clearInput,
      changeCurrentId,
      sumInput,
      bottom
    }
  }
}
</script>

<style scoped lang="scss">
.finance__list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 24px;
  @include desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 24px;
  }
}

.finance__payment {
  border-radius: 8px;
  border: 1px solid #BAC7DE;
  background: #FFF;
  width: 100%;
  height: 40px;
  padding: 11px;

  &::placeholder {
    color: #BAC7DE;
    font-size: 14px;
    font-weight: 350;
    line-height: 100%;
  }
}

.finance__btn {
  color: #062E4F;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  border-radius: 30px;
  background: var(--blue-1, #BAC7DE);
  padding: 11px;
  width: 100%;
  border: none;
  margin-top: 16px;
  margin-bottom: 24px;
}

.finance__control-label {
  color: #002856;
  font-size: 14px;
  font-weight: 350;
  line-height: 100%;
  margin-bottom: 4px;
}

.error {
  margin-bottom: 40px;
}
</style>
