<template>
  <div class="block-contacts">
    <div class="block-contacts__title">Как получить скидку?</div>
    <div class="block-contacts__list">
      <div class="block-contacts__item">
        <div class="block-contacts__item-icon">
          <img src="@/assets/uploads/sprite/call-dark.svg" alt="телефон">
        </div>
        <div class="block-contacts__item-text">Позвонить нам по номеру <a href="tel:+74951626515">+7
          (495) 162-65-15</a></div>
      </div>
      <div class="block-contacts__item">
        <div class="block-contacts__item-icon">
          <img src="@/assets/uploads/sprite/online.svg" alt="телефон">
        </div>
        <div class="block-contacts__item-text">или <span>Записаться онлайн, заполнив форму</span></div>
      </div>
      <div class="block-contacts__item">
        <div class="block-contacts__item-icon">
          <img src="@/assets/uploads/sprite/reception.svg" alt="телефон">
        </div>
        <div class="block-contacts__item-text">Сообщить администратору на ресепшн, что вы пришли по
          акции
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BlockContacts',
  props: {},
  setup (props) {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.block-contacts__title {
  color: #BAC7DE;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  background: #002856;
  padding: 7px 30px;
  border-radius: 30px;
  text-align: center;
  margin: 0 auto -18px;
  z-index: 100;
  position: relative;
  width: 90%;
  @include desktop {
    margin: 0 0 25px;
    max-width: 285px;
  }
}

.block-contacts {
  @include desktop {
    background: #E1E8F4;
    padding: 24px 16px;
    border-radius: 6px;
  }
}

.block-contacts__list {
  background: #E1E8F4;
  padding: 42px 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 6px;
  @include desktop {
    background: none;
    padding: 0;
    border-radius: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.block-contacts__item {
  display: flex;
  align-items: center;
  @include desktop {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}

.block-contacts__item-text {
  color: #002856;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  a {
    font-weight: 700;
    display: block;
  }
  span {
    font-weight: 700;
  }
}

.block-contacts__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #002856;
  margin-right: 16px;
}
</style>
