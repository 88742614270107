<template>
  <component
      :is="tag"
      class="custom-btn"
      :class="{'custom-btn--white': white}"
      :href="tag === 'a' ? href : null"
      :type="type"
  >
    <div class="custom-btn__wrapper">
      <slot name="img">
        <div></div>
      </slot>

      <slot/>
    </div>
  </component>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    white: {
      type: Boolean,
      required: false,
      default: false
    },
    tag: {
      type: String,
      required: false,
      default: 'a'
    },
    bgColor: {
      type: String,
      required: false,
      default: '#BAC7DE'
    },
    marginBottom: {
      type: String,
      required: false,
      default: '10px'
    },
    type: {
      type: [String, null],
      default: null,
      required: false
    },
    color: {
      type: String,
      default: '#002856',
      required: false
    },
    href: {
      type: String,
      default: '',
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
.custom-btn {
  display: block;
  width: 100%;
  padding: 12px 30px;
  text-align: center;
  text-transform: uppercase;
  color: v-bind('color');
  border-radius: 30px;
  background-color: v-bind(bgColor);
  margin-bottom: v-bind('marginBottom');
  line-height: 16px;
  border: unset;
  transition: 0.2s;

  &:active {
    background: #e1e8f4;
    text-decoration: none;
    color: #062e4f;
  }

  &:hover {
    background: #e1e8f4;
    text-decoration: none;
    color: #062e4f;
  }

  &--white {
    background: #FFFFFF;
    border: 1px solid #002856;

    &:active {
      background: #002856;
      color: #fff;
    }

    &:hover {
      background: #002856;
      color: #fff;
    }
  }

  &__wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
  }
}
</style>
