<template>
  <div class="otp-form">

    <h4 v-if="!preCode" class="label" :class="{error: error}">
      {{ isPhone ? 'Номер телефона' : ' Код из смс' }}
    </h4>
    <div class="wrapper">
      <p class="default-num" v-if="isPhone">
        +7
      </p>
      <p class="default-num" v-if="preCode">
        {{ preCode }}
      </p>
      <!--      <VOtpInput-->
      <!--          v-model:value="bindModal"-->
      <!--          input-classes="otp-input"-->
      <!--          separator=""-->
      <!--          :num-inputs="digitCount"-->
      <!--          :should-auto-focus="true"-->
      <!--          input-type="number"-->
      <!--          @on-complete="$emit('otpCompleted')"-->
      <!--      />-->
      <OTPSingleInput v-model="bindModal" :digit-count="digitCount" @isCompleted="$emit('isCompleted')"/>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue'
import OTPSingleInput from '@/components/pages/auth/OTPSingleInput.vue'

export default {
  name: 'OtpForm',
  components: {
    OTPSingleInput
  },
  emits: ['update:modelValue', 'otpCompleted', 'isCompleted'],
  props: {
    // defaultNum: String,
    modelValue: {
      type: String,
      required: true,
      default: ''
    },
    preCode: {
      type: String,
      required: false,
      default: ''
    },
    error: Boolean,
    digitCount: {
      type: Number,
      required: true
    },

    isPhone: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  setup (props, { emit }) {
    const { modelValue } = toRefs(props)

    const bindModal = computed({
      get () {
        return modelValue.value
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })

    return {
      bindModal
    }
  }
}
</script>

<style>
.otp-input {
  margin: 0 1px;
  padding: 0;
  width: 24px;
  height: 32px;
  border: 1px solid #BAC7DE;
  border-radius: 8px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
}

/* Background colour of an input field with value */
.otp-input.is-complete {
  /*background-color: #BAC7DE;*/
}

.otp-input:focus {
  outline: 1.5px solid #BAC7DE;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>

<style scoped lang="scss">
.label {
  color: $blue;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 5px;

  &.error {
    color: $red;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.default-num {
  font-size: 16px;
  margin-right: 5px;

}

</style>
