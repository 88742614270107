<template>
  <ul class="med-tabs">
    <li class="med-tabs__item" v-for="tab in tabs" :key="tab.title">
      <button
          type="button"
          class="med-tabs__links"
          :class="{active: currentTab.title === tab.title}"
          @click="changeActiveTab(tab)"
      >
        {{ tab.title }}
      </button>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'TheTabs',
  emits: ['changeTab'],
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => ([])
    },
    currentTab: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  setup (props, { emit }) {
    const changeActiveTab = (tab) => {
      emit('changeTab', tab)
    }
    return {
      changeActiveTab
    }
  }
}
</script>

<style scoped lang="scss">
.med-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-bottom: 15px;

  &__item {
    //flex: 1;
    flex: 1 0 auto;
  }

  &__links {
    width: 100%;
    padding: 0;
    margin: 0;
    border: unset;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #BAC7DE;
    color: #BAC7DE;
    font-size: 12px;

    &.active {
      color: #002856;
      border-bottom: 2px solid #002856;
    }
  }
}
</style>
