<template>
  <section class="section actual" v-if="actualList">
    <div class="container">
      <SectionTitle title="Актуальное" :mobile-white="true"></SectionTitle>
    </div>

    <Teleport to="body">
      <TheStories
          v-if="showStories"
          :currentIndex="selectedStories"
          :duration="5000"
          :stories="itemsStories"
          :showInnerContent="true"
          @closeStories="showStories = false"
      >

        <template v-slot:innerContent="{ story }">
          <div class="story__content" v-if="story.storiesItems">
            <div class="story__description">
              <div class="story__type" v-html="story.storiesItems.type"></div>
              <div class="story__title" v-html="story.storiesItems.name"></div>
              <div class="story__text" v-html="story.storiesItems.text"></div>
              <a
                  :href="story.storiesItems.link"
                  target="_blank"
                  class="story__link"
              >Подробнее</a
              >
            </div>
          </div>
        </template>
      </TheStories>
    </Teleport>

    <div class="actual__swiper">
      <!--      Because of nature of how the loop mode works (it will rearrange slides), total number of slides must be >= slidesPerView * 2-->
      <Swiper
          v-bind="swiperOptions"
          :loop="isLoop"
          class="actual__swiper-wrapper"
      >
        <swiper-slide v-for="(actual, index) in actualList" :key="actual.name">
          <ActualSwiperElement
              :img="actual.img"
              :text="actual.name"
              :href="actual.link"
              @click="actual.type === 'story' ? openStorires(index) : null"
          />
        </swiper-slide>
        <div class="swiper-custom__pagination"></div>
      </Swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import SectionTitle from '@/components/ui/typography/SectionTitle.vue'
import ActualSwiperElement from '@/components/pages/home/ActualSwiperElement.vue'
import axios from 'axios'
import { computed, onMounted, ref } from 'vue'
import TheStories from '@/components/ui/TheStories.vue'
import { Pagination } from 'swiper'

export default {
  name: 'ActualSection',
  components: {
    TheStories,
    ActualSwiperElement,
    SectionTitle,
    Swiper,
    SwiperSlide
  },
  setup () {
    const selectedStories = ref(0)
    const showStories = ref(false)
    const itemsStories = ref([])
    const swiperOptions = ref({
      modules: [Pagination],
      pagination: {
        el: '.swiper-custom__pagination',
        clickable: true
      },
      spaceBetween: 5,
      breakpoints: {
        0: {
          slidesPerView: 2.7
        },
        430: {
          slidesPerView: 3.3
        },
        550: {
          slidesPerView: 4.2
        },
        710: {
          slidesPerView: 5.4
        },
        820: {
          slidesPerView: 6
        },
        991: {
          slidesPerView: 4,
          slidesPerGroup: 4
        },
        1200: {
          slidesPerView: 6,
          slidesPerGroup: 6
        }
      }
    })
    const actualList = ref(null)
    const isLoop = computed(() => {
      if (!actualList.value) return false

      return actualList.value.length > 6
    })

    const getActualList = async () => {
      try {
        const response = await axios.get('/v3/main/actual/')

        if (response.data.status === 'ok') {
          actualList.value = response.data.data
          itemsStories.value = actualList.value
            .filter((el) => el.type === 'story')
            .map((el) => el.item)
        }
      } catch (err) {
        throw new Error(err)
      }
    }

    const openStorires = (storiesIndex) => {
      let skipLink = 0

      for (let i = 0; i < storiesIndex; i++) {
        if (actualList.value[i].type === 'link') {
          skipLink++
        }
      }

      selectedStories.value = storiesIndex - skipLink
      showStories.value = true
    }

    onMounted(async () => {
      await getActualList()
    })

    return {
      isLoop,
      actualList,
      swiperOptions,
      selectedStories,
      showStories,
      itemsStories,
      openStorires
    }
  }
}
</script>

<style lang="scss">
.actual {
  padding: 0 0 10px 0;

  &__swiper {
    padding-left: 15px;
    overflow: hidden;

    .swiper-custom__pagination {
      text-align: center;
      margin-top: 20px;

      @include phone-xs {
        display: none;
      }

      .swiper-pagination-bullet {
        border: 1px solid #002856;
        background: none;
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background: #002856;
        }
      }
    }
  }
}

</style>
