<template>
  <ArrowTitle title="Беременность"></ArrowTitle>
  <SectionWrapper stretched>
    <div class="calendar">
    </div>
  </SectionWrapper>
</template>

<script>

import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'

export default {
  name: 'CalendarView',
  components: { SectionWrapper, ArrowTitle }
}
</script>

<style scoped lang="scss">
</style>
