<template>
  <SectionWrapper stretched>
    <ArrowTitle title="Акция" :dark-title="true" marginBottom="16px"></ArrowTitle>
    <section class="offers" v-if="isWidgets">
      <template v-for="(widget, ix) in widgets" :key="'widget'+ix">
        {{ widget.id }}
        <component :is="widget.widget" :data="widget.data" :alias="widget.alias" :user="user"
                   @scroll="scrollToAppointment"></component>
      </template>
      <BlockContacts></BlockContacts>
      <BlockAppointment :user="user" v-if="user" :offer-id="offer.id"></BlockAppointment>
      <div ref="blockAppointment"></div>
    </section>
  </SectionWrapper>
</template>

<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import FormInputCheckbox from '@/components/ui/form/FormInputCheckbox.vue'
import BlockRate from '@/components/pages/offers/BlockRate.vue'
import BlockApp from '@/components/pages/offers/BlockApp.vue'
import BlockDoctorWord from '@/components/pages/offers/BlockDoctorWord.vue'
import BlockOfferMain from '@/components/pages/offers/BlockOfferMain.vue'
import BlockAddress from '@/components/pages/offers/BlockAddress.vue'
import BlockAppointment from '@/components/pages/offers/BlockAppointment.vue'
import BlockPrice from '@/components/pages/offers/BlockPrice.vue'
import { computed, onActivated, reactive, ref } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import BlockContacts from '@/components/pages/offers/BlockContacts.vue'

export default {
  name: 'OneOffersView',
  components: {
    BlockContacts,
    FormInputCheckbox,
    SectionWrapper,
    ArrowTitle,
    BlockRate,
    BlockApp,
    BlockDoctorWord,
    BlockOfferMain,
    BlockAddress,
    BlockAppointment,
    BlockPrice
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },

  setup () {
    const loading = ref(false)
    const isWidgets = ref(false)
    const widgets = reactive([])
    const offer = ref(null)
    const blockAppointment = ref(null)
    const route = useRoute()
    const alias = computed(() => {
      return route.params.alias
    })

    const scrollToAppointment = () => {
      console.log('2')
      blockAppointment.value.scrollIntoView(false)
    }
    const getOfferData = async (alias) => {
      try {
        loading.value = true
        const response = await axios.get(`v1/promo/view/?alias=${alias}`)
        if (response.data) {
          widgets.splice(0, widgets.length)

          isWidgets.value = true
          offer.value = response.data
          for (let i = 0; i < offer.value.widgets.length; i++) {
            if (offer.value.widgets[i].widget_id === 'LandHeaderWidget') {
              widgets.push({ widget: 'BlockOfferMain', data: offer.value.widgets[i], alias: offer.value.alias })
            } else if (offer.value.widgets[i].widget_id === 'LandAddressWidget') {
              widgets.push({ widget: 'BlockAddress', data: offer.value.widgets[i] })
            } else if (offer.value.widgets[i].widget_id === 'LandRateWidget') {
              widgets.push({ widget: 'BlockRate', data: offer.value.widgets[i] })
            } else if (offer.value.widgets[i].widget_id === 'LandWordDoctorWidget') {
              widgets.push({ widget: 'BlockDoctorWord', data: offer.value.widgets[i] })
            } else if (offer.value.widgets[i].widget_id === 'LandPriceWidget') {
              widgets.push({ widget: 'BlockPrice', data: offer.value.widgets[i] })
            }
          }

          return response.data.data
        } else {
          return null
        }
      } catch (err) {
        throw new Error()
      } finally {
        loading.value = false
      }
    }

    onActivated(async () => {
      isWidgets.value = false

      await getOfferData(alias.value)
    })

    return {
      isWidgets,
      widgets,
      getOfferData,
      offer,
      scrollToAppointment,
      blockAppointment
    }
  }
}
</script>

<style scoped lang="scss">
.offers {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
</style>
