<template>
  <div class="finger">
    <img src="@/assets/uploads/images/header/logo.svg" alt="Логотип к31">
  </div>
</template>

<script>

import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import { inject } from 'vue'
import { deleteCookieToken } from '@/utilits/deleteCookieToken'

export default {
  name: 'FingerView',

  setup () {
    const router = useRouter()
    const userStore = useUserStore()
    const { setUserToken, setUser } = userStore
    const toast = inject('toast')
    const showHelloToaster = () => {
      toast.success('Добро пожаловать!', {
        position: 'top-right',
        type: 'success'
      })
    }

    new Promise((resolve, reject) => {
      window.Fingerprint.isAvailable(() => {
        window.Fingerprint.loadBiometricSecret({
          description: 'Для работы с личным кабинетом надо пройти аутентификацию',
          disableBackup: true // always disabled on Android
        }, (suc) => {
          resolve(suc)
        }, (err) => {
          reject(err)
        })
      }, (err) => {
        reject(err)
      })
    })
      .then(secret => {
        setUserToken(secret)
        return setUser(secret)
      })
      .then(() => {
        router.push({
          name: 'home'
        })
        showHelloToaster()
      })
      .catch(() => {
        deleteCookieToken()
        router.push({
          name: 'home'
        })
      })
  }
}
</script>

<style scoped lang="scss">

.finger {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 100vh;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    box-shadow: 1px 1px 2.5rem 1px rgba(0, 0, 0, .1);
    padding: 20px;
    border-radius: 20px;
    background: #FFFFFF;
  }
}

</style>
