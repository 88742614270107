import { getCookie } from '@/utilits/getCookie'
import axios from 'axios'

const postFirebaseToken = async (path, method = 'get', data = {}) => {
  const userToken = getCookie('user_token')

  try {
    const response = await axios({
      method: method,
      url: path,
      data: data,
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      }
    })

    if (response.data.status === 'ok') {
      return [null, response.data.data]
    } else {
      return [new Error(response.data.message), null]
    }
  } catch (err) {
    console.warn(err)
    return [err, null]
  }
}

const success = async (token) => {
  const data = {
    token: token,
    device: window?.device?.platform.toLowerCase()
  }
  const [error, notifyResponse] = await postFirebaseToken(
    '/v1/push/token',
    'post',
    data
  )

  return [error, notifyResponse]
}

const errorCallBack = (error) => {
  console.warn(error)
}
export const getFirebaseToken = () => {
  const platform = window?.device?.platform.toLowerCase()

  if (platform !== 'ios' && platform !== 'android') {
    return
  }

  const pushToken = () => {
    new Promise((resolve, reject) => {
      window?.FirebasePlugin?.getToken(
        (token) => {
          resolve(token)
        },
        (err) => {
          reject(err)
        }
      )
    })
      .then(async (token) => {
        await success(token)
      })
      .catch((err) => {
        errorCallBack(err)
      })
  }

  window?.FirebasePlugin?.grantPermission(function () {
    pushToken()
  })
}
