<template>
  <ActualSection/>
  <PopularServicesSection/>
  <IndividualProgramsSection :user="user"/>
  <MyAppointmentSection :user="user"/>
</template>

<script>

import PopularServicesSection from '@/components/pages/home/PopularServicesSection.vue'
import MyAppointmentSection from '@/components/pages/home/MyAppointmentSection.vue'
import ActualSection from '@/components/pages/home/ActualSection.vue'
import IndividualProgramsSection from '@/components/pages/home/IndividualProgramsSection.vue'

export default {
  name: 'HomeView',
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  components: {
    IndividualProgramsSection,
    ActualSection,
    MyAppointmentSection,
    PopularServicesSection
  }
}
</script>
