<template>
  <ArrowTitle title="Календари"></ArrowTitle>
  <SectionWrapper stretched>
    <div class="calendar">
      <div class="calendar__text">
        <p>
          Уважаемый пациент, вы можете завести свои медицинские календари в личном кабинете К+31.
        </p>
        <p>
          Вам доступны календари для следующих задач:
        </p>
      </div>
      <div class="calendar__wrapper">
        <div class="calendar__title">Выбор календаря</div>
        <ul class="calendar__tabs">
          <li v-for="tab in calendarTabs" :key="tab.to">
            <router-link :to="tab.to">
              <div class="calendar__tabs-name">
                <img
                  :src="require(`@/assets/uploads/images/calendar/${tab.imgName}.svg`)"
                  alt="иконка"
                />
                {{ tab.title }}
              </div>
              <div class="calendar__tabs-arrow">
                <img
                  src="@/assets/uploads/icon/common/arrow-back.svg"
                  alt="стрелка">
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </SectionWrapper>
</template>

<script>

import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import { ref } from 'vue'

export default {
  name: 'CalendarView',
  components: { SectionWrapper, ArrowTitle },
  setup () {
    const currentTab = ref('my-events')
    const calendarTabs = ref([
      {
        to: 'calendar-appointments',
        imgName: 'appointments',
        title: 'Мои приёмы',
        disabled: false
      },
      // {
      //   to: 'calendar-pregnancy',
      //   imgName: 'pregnancy',
      //   title: 'Беременность',
      //   disabled: true
      // },
      {
        to: 'calendar-vaccination',
        imgName: 'vaccination',
        title: 'Вакцинация',
        disabled: false
      }
    ])

    return {
      currentTab,
      calendarTabs
    }
  }
}
</script>

<style scoped lang="scss">
.calendar__text {
  p {
    color: #002856;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.32px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
.calendar__title {
  margin-top: 12px;
  color: #002856;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.calendar__tabs {
  margin-top: 6px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.10);
  padding: 16px 22px;
  li {
    &:not(:last-child) {
      border-bottom: 1px solid #E1E8F4;
      padding-bottom: 24px;
      margin-bottom: 24px;
    }
    a {
      color: #002856;
      font-size: 12px;
      font-weight: 700;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.calendar__tabs-name {
  display: flex;
  align-items: center;
  img {
    margin-right: 12px;
  }
}

.calendar__tabs-arrow {
  transform: rotate(180deg);
  filter: invert(11%) sepia(35%) saturate(4709%) hue-rotate(200deg) brightness(91%) contrast(101%);
}
</style>
