<template>
  <router-link to="" class="notification-bell">
    <!--    <div class="notification-bell__counter" v-if="userToken">-->
    <!--      5-->
    <!--    </div>-->
    <IconInCircle :bg-color="colorNotify" width="32" height="32">
      <img src="@/assets/uploads/images/header/bell-notify.svg" alt="картинка колокольчика">
    </IconInCircle>
  </router-link>
</template>

<script>
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import IconInCircle from '@/components/ui/IconInCircle.vue'
import { computed, watch } from 'vue'
import axios from 'axios'

export default {
  name: 'NotificationBell',
  components: { IconInCircle },
  setup () {
    const userStore = useUserStore()
    const { userToken } = storeToRefs(userStore)

    const colorNotify = computed(() => {
      return userToken.value ? '#E1E8F4' : '#E1E8F4'
    })

    watch(() => userToken.value, async () => {
      try {
        const response = await axios('/v1/push', {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })
        console.log('response', response)
      } catch (err) {
        console.warn('err', err)
      }
    })

    return {
      userToken,
      colorNotify
    }
  }
}
</script>

<style scoped lang="scss">
.notification-bell {
  position: relative;
  width: 32px;
  height: 32px;
  display: block;

  &__counter {
    right: -3px;
    top: -4px;
    width: 14px;
    height: 14px;
    font-family: 'Open Sans', serif;
    position: absolute;
    background: #D60000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
  }
}
</style>
