<script>
import { ref, reactive, watch, toRefs, computed } from 'vue'
import TheText from '@/components/ui/typography/TheText.vue'
import MenuItem from '@/components/layout/menu/MenuItem.vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'TheMenu',
  components: { MenuItem, TheText },
  emits: ['toggleMenu', 'close'],
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  setup (props, { emit }) {
    const { show } = toRefs(props)
    const userStore = useUserStore()
    const { userToken } = storeToRefs(userStore)
    const menuIsActive = ref(false)
    const { user } = storeToRefs(userStore)
    watch(show, () => {
      if (show.value) {
        window.scrollBy(0, -100000)
      }
    })

    const sidebarLinks1 = reactive([
      {
        imgName: 'home-page',
        title: 'Главная',
        pathName: '/'
      },
      {
        imgName: 'appointment',
        title: 'Запись на прием',
        pathName: '/online-appointment',
        locked: true
      },
      {
        title: 'Мой профиль',
        imgName: 'profile',
        pathName: '/profile',
        locked: true
      },
      {
        imgName: 'family',
        title: 'Моя семья',
        pathName: '/profile/family',
        locked: true
      },
      {
        imgName: 'document',
        title: 'Медкарта',
        pathName: '/med-card',
        locked: true
      },
      {
        imgName: 'doctor',
        title: 'Врачи',
        pathName: '/doctors',
        locked: true
      },
      {
        imgName: 'calendar',
        title: 'Календари',
        pathName: '/calendar',
        locked: true
      },
      {
        imgName: 'call-doctor',
        title: 'Вызов врача на дом',
        pathName: '/call-doctor',
        locked: true
      },
      {
        imgName: 'wallet-filled-money',
        title: 'Финансы',
        pathName: '/finance',
        locked: true
      },
      {
        imgName: 'folder',
        title: 'Мои файлы',
        pathName: '/files',
        locked: true
      },
      {
        imgName: 'checklist',
        title: 'Подготовка к исследованию',
        pathName: '/preparation'
      }
    ])

    const sidebarLinks3 = reactive([
      {
        title: 'Оставить отзыв',
        imgName: 'review',
        pathName: '',
        url: 'https://www.k31.ru/about/review/rate/?utm_medium=lk',
        locked: true
      },
      {
        title: 'Написать в Whatsapp',
        imgName: 'whats-app',
        pathName: '#',
        whatsApp: true
      },
      // {
      //   title: 'Техподдержка',
      //   imgName: 'ask',
      //   pathName: '/support',
      //   locked: true
      // },
      {
        title: 'Паркинг',
        imgName: 'parking',
        pathName: '/parking',
        locked: true
      }
    ])

    watch(() => userToken.value, () => {
      if (userToken.value) {
        sidebarLinks1.forEach(item => {
          if (item?.locked) {
            item.locked = false
          }
        })

        sidebarLinks3.forEach(item => {
          if (item?.locked) {
            item.locked = false
          }
        })
      }
    }, {
      immediate: true
    })
    const toggleMenu = () => {
      emit('toggleMenu')
    }
    const logOut = () => {
      userStore.logOut()
    }
    const getCardsLength = computed(() => {
      if (!user.value) return null

      return user.value.family_cards.length
    })

    return {
      menuIsActive,
      toggleMenu,
      sidebarLinks1,
      sidebarLinks3,
      userToken,
      user,
      getCardsLength,
      logOut
    }
  }
}
</script>

<template>
  <Transition name="modal">
    <div v-show="show" class="modal-mask">
      <div class="menu__title">
        <div class="menu__back" @click="$emit('close')">
          <img
              src="@/assets/uploads/icon/common/arrow-back.svg"
              alt="стрелка">
        </div>
        <h3>Меню</h3>
      </div>

      <div class="modal-container">
        <div class="modal-header">
          <TheText v-if="!userToken">
            Часть функционала доступна только после входа
          </TheText>
        </div>

        <div class="modal-body">
          <slot name="body">
            <div class="menu__user" v-if="user">
              <div class="menu__user-info">
                <div class="menu__user-name">{{ user.last_name }} {{ user.first_name }}</div>
              </div>
              <div class="menu__user-logout" @click="logOut">
                Выйти
                <img src="@/assets/uploads/images/sidebar/logout.svg" alt="">
              </div>
            </div>
            <ul class="menu__list">
              <li class="menu__item" v-for="link in sidebarLinks1" :key="link.title" @click="$emit('close')">
                <MenuItem :img-name="link.imgName" :path-name="link.pathName" :locked="link.locked" :title="link.title">

                </MenuItem>
              </li>
            </ul>

            <div class="menu__offers" @click="$emit('close')">
              <div class="menu__offer">
                <router-link to="/offers" class="menu__offer-wrapepr">
                  <h5>
                    Акции
                  </h5>
                  <img src="@/assets/uploads/images/sidebar/sales.png" alt="Акции">
                </router-link>

              </div>
              <router-link to="/program/all" class="menu__programm">
                Комплексные программы
              </router-link>
            </div>

            <ul class="menu__list">
              <li class="menu__item" v-for="link in sidebarLinks3" :key="link.title" @click="$emit('close')">
                <MenuItem :whatsApp="link.whatsApp" :img-name="link.imgName" :path-name="link.pathName"
                          :locked="link.locked" :title="link.title" :url="link.url">

                </MenuItem>
              </li>
            </ul>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
.menu__user {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
  @include desktop {
    //display: none;
  }
}

.menu__user-name {
  color: #002856;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 8px;
}

.menu__user-cards {
  color: #002856;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.28px;
  text-decoration: underline !important;
}

.menu__user-logout {
  color: #002856;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.28px;
  @include desktop {
    //display: none;
  }

  img {
    margin-left: 8px;
  }
}

.menu__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  padding-bottom: 8px;
  transition: background-color 1s ease-in-out;
  background-color: $blue;
  @include desktop {
    display: none;
  }

  & h3 {
    margin-right: 25px;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.36px;
  }
}

.menu__list {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.menu__offers {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.menu__offer {
  flex: 1 1 45%;
  border-radius: 6px;
  border: 1px solid #F1842C;
  padding: 4px;
}

.menu__offer-wrapepr {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  padding: 6px 10px;
  border-radius: 6px;
  background: #BAC7DE;

  color: $blue;
  font-family: Circe;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.32px;
}

.menu__programm {
  color: $blue;
  font-family: Circe;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.32px;
  padding: 10px 16px;
  border-radius: 6px;
  background: #BAC7DE;
  flex: 1 1 45%;
  background: linear-gradient(125deg, #E1E8F4 0%, #BFCDE3 100%);
}

.menu__back {
  & img {
    padding-left: 15px;
    filter: invert(97%) sepia(1%) saturate(501%) hue-rotate(197deg) brightness(116%) contrast(100%);
  }
}

.modal-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: opacity 0.3s ease;
  flex-direction: column;
  @include desktop {
    display: flex;
    position: relative;
    grid-column: 1;
    grid-row: 1/4;
  }
}

.modal-container {
  //flex: 1 1 auto;
  height: 100%;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  @include desktop {
    height: auto;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: translateY(100vh);
  transform: translateY(100vh);
}
</style>
