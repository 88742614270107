import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios from 'axios'
import 'bootstrap'
import Toaster from '@meforma/vue-toaster'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const baseUrl = 'https://lk.k31.ru/api'

axios.defaults.baseURL = baseUrl

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(router)
app.provide('dayjs', dayjs)
app
  .use(Toaster, { position: 'top-right' })
  .provide('toast', app.config.globalProperties.$toast)

if (process.env.NODE_ENV === 'development') {
  app.mount('#app')
} else {
  app.mount('#app')
  // document.addEventListener('deviceready', function () {
  //   app.mount('#app')
  // })
}

document.addEventListener('gesturestart', (event) => {
  event.preventDefault()
})
