<template>
  <div class="block-doctor-word" v-if="data.json.doctors">
    <div class="block-doctor-word__quotes">
      <img src="@/assets/uploads/icon/common/quotes.svg" alt="кавычки">
    </div>
    <div
      v-for="item in data.json.doctors"
      :key="item.id"
    >
      <div class="block-doctor-word__content">
        <div class="block-doctor-word__text" v-html="item.description"></div>
        <div class="block-doctor-word__info">
          <div class="block-doctor-word__info-img">
            <img :src="item.img" alt="">
          </div>
          <div class="block-doctor-word__info-text">
            <div class="block-doctor-word__name">{{ item.name }}</div>
            <div class="block-doctor-word__spec">{{ item.position }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BlockDoctorWord',
  props: ['data']
}
</script>

<style lang="scss" scoped>
.block-doctor-word__text {
  color: #002856;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
  margin-bottom: 24px;
  p {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  @include desktop {
    font-size: 16px;
  }
}
.block-doctor-word__info {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  @include desktop {
    align-items: center;
  }
}
.block-doctor-word__info-img {
  img {
    min-height: 65px;
    min-width: 65px;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    object-fit: cover;
    @include desktop {
      min-height: 75px;
      min-width: 75px;
      height: 75px;
      width: 75px;
    }
  }
}

.block-doctor-word__name {
  color: #002856;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.14px;
  margin-bottom: 5px;
  @include desktop {
    font-size: 16px;
  }
}
.block-doctor-word__spec {
  color: #002856;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.14px;
  @include desktop {
    font-size: 16px;
  }
}

.block-doctor-word__quotes {
  text-align: center;
  margin-bottom: 24px;
  @include desktop {
    text-align: left;
  }
}
</style>
