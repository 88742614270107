<template>
  <section class="individual-programs">
    <div class="container">
      <a href="https://www.k31.ru/service/checkup/" target="_blank" class="individual-programs__wrapper">
        <div class="individual-programs__link">
          <SectionTitle title="Индивидуальные программы" marginBottom="0" class="individual-programs__title"></SectionTitle>
          <div class="text text--blue">
            <p>
              Составлены нашими специалистами специально для вас
            </p>
          </div>
          <img src="@/assets/uploads/images/home-page/programs.svg" alt="Программы">
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/ui/typography/SectionTitle.vue'
import { computed, toRefs } from 'vue'

export default {
  name: 'IndividualProgramsSection',
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  components: { SectionTitle },
  setup (props) {
    const { user } = toRefs(props)
    const pathName = computed(() => {
      if (user.value) {
        return '/program/my'
      }

      return '/auth/phone'
    })
    return {
      pathName
    }
  }
}
</script>

<style scoped lang="scss">
.individual-programs__wrapper {
  border: 1px solid #F1842C;
  border-radius: 8px;
  display: block;
  padding: 2px;
  @include desktop {
    padding: 0;
    border: none;
  }
}
.individual-programs {
  margin-bottom: 12px;
  &__link {
    background: #BAC7DE;
    border-radius: 6px;
    padding: 12px 14px;
    position: relative;
    transition: background .1s ease-in-out;
    img {
      display: none;
      position: absolute;
      right: 0;
    }
    @include desktop {
      padding: 18px;
      background: #F1842C;
      img {
        display: block;
      }
      display: grid;
      grid-template-columns: 2fr 1fr;
      .text {
        grid-column: 1;
      }
    }
  }
}
.individual-programs__title {
  @include desktop {
    margin-bottom: 8px;
  }
}
</style>
