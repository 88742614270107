<template>
  <div class="appointment__wrapper" v-if="appointmentUserList && !loading">
    <p class="my-appointment__text">
      Скоро у Вас
      <span>
          {{ appointmentUserList.length }}
      </span>
      {{ numberOfAppointment }}
    </p>
    <ul class="appointment-list">
      <AppointmentListItem
          v-for="appointment in appointmentUserList"
          :key="appointment.id"
          :date="appointment.date"
          :doctorInfo="appointment.resource_name"
          :calendarLink="appointment.ics_url"
          :resourceId="appointment.id"
          :appointment="appointment"
          @delete-appointment="deleteAppoint"
      />
    </ul>
  </div>

  <div class="my-appointment__bottom">
    <p v-if="!appointmentUserList && !loading">
      У вас нет активных записей
    </p>

    <TheLoader v-if="loading">
      Загрузка ваших записей
    </TheLoader>
  </div>
  <!--  <CustomButton @click="showErrorToaster" v-if="!appointmentUserList && !loading">-->
  <!--    Записаться через колл-центр-->
  <!--  </CustomButton>-->

  <CustomButton white v-if="!appointmentUserList && !loading">
    <router-link to="/online-appointment">
      выбрать специалиста
    </router-link>
  </CustomButton>
</template>

<script>
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { ref, computed, inject, watch, onActivated } from 'vue'
import AppointmentListItem from '@/components/pages/home/AppointmentListItem.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'

export default {
  name: 'AppointmentList',
  components: { TheLoader, AppointmentListItem, CustomButton },
  props: {
    user: {
      type: Object,
      required: true,
      default: null
    }
  },

  setup () {
    const toast = inject('toast')

    const showErrorToaster = () => {
      toast.error('Функционал в разработке', {
        position: 'top-right'
      })
    }
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const appointmentList = ref(null)
    const loading = ref(false)

    const appointmentUserList = computed(() => {
      if (appointmentList.value && appointmentList.value.length > 0) return appointmentList.value

      return null
    })

    const numberOfAppointment = computed(() => {
      if (appointmentList.value) {
        return appointmentList.value.length > 1 ? 'приема' : 'прием'
      }

      return null
    })
    const deleteAppoint = async (appointmentId) => {
      try {
        loading.value = true
        const response = await axios({
          method: 'delete',
          url: `v1/element/schedule/reserve/delete/?user_id=${getUserId.value}`,
          data: {
            reserve_id: appointmentId.value
          },
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${userToken.value}`,
            'Cache-Control': 'no-cache'
          }
        })

        if (response.data.status === 'ok') {
          console.log('ок')
          appointmentList.value = await getUserAppointment(getUserId.value)
        } else {
          return null
        }
      } catch (err) {
        console.log(err)
      } finally {
        loading.value = false
      }
    }
    const getUserAppointment = async (id) => {
      try {
        loading.value = true
        const response = await axios.get(`/v1/element/event/index/?user_id=${id}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          return response.data.data
        } else {
          return null
        }
      } catch (err) {
        throw new Error()
      } finally {
        loading.value = false
      }
    }

    watch(() => getUserId.value, async () => {
      if (getUserId.value) {
        appointmentList.value = await getUserAppointment(getUserId.value)
      }
    },
    {
      immediate: true
    })

    onActivated(async () => {
      if (getUserId.value) {
        appointmentList.value = await getUserAppointment(getUserId.value)
      }
    })

    return {
      appointmentUserList,
      loading,
      numberOfAppointment,
      showErrorToaster,
      deleteAppoint
    }
  }
}
</script>

<style scoped lang="scss">
.my-appointment__text {
  margin-bottom: 10px;
}

.my-appointment__bottom {
  margin-bottom: 30px;

  & span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #F1842C;
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }
}

.appointment-list {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
  color: #7F8DA9;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    padding: 8px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__time {
    margin-right: 10px;
    font-weight: 700;
    color: #222222;
  }

  &__date {
    margin-right: auto;
  }

  &__type {
    padding: 2px 8px;
    background: #002856;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__picture {
  }

  &__info {
  }

  &__department {
    margin-bottom: 4px;
  }

  &__title {
    color: #002856;
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filial {
    margin-right: auto;
  }

  &__calendar {
    text-decoration-line: underline;
    color: #002856;
  }
}

</style>
