<template>
  <router-link :to="pathName" class="sidebar__link">
    <img :src="require(`@/assets/uploads/images/sidebar/${imgName}.svg`)" :alt="title">
    <h5>
      {{ title }}
    </h5>
  </router-link>
</template>

<script>

export default {
  name: 'SidebarLink',
  props: {
    title: {
      type: String,
      require: true
    },
    imgName: {
      type: String,
      require: true
    },
    pathName: {
      type: String,
      require: false,
      default: '#'
    }
  }
}
</script>

<style scoped>

</style>
