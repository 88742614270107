<template>
  <div class="block-address">
    <ul>
      <li v-for="item in data.json.clinics" :key="item.id">
        <img src="@/assets/uploads/icon/common/pin.svg" alt="pin">
        {{item.title}}
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'BlockAddress',
  props: ['data']
}
</script>

<style lang="scss">
.block-address {
  border-radius: 6px;
  background: #E1E8F4;
  height: 60px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include desktop {
    justify-content: flex-start;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
    @include desktop {
      gap: 64px;
    }
    li {
      white-space: nowrap;
      color: #002856;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      @include desktop {
        font-size: 16px;
      }
    }
  }
}
</style>
