<template>
  <div class="vaccination__item">
    <p class="vaccination__item-type">
      {{ vaccine.namegrp }}
    </p>
    <p class="vaccination__item-count">
      Количество вакцин: {{ vaccine.count }}
    </p>
    <p class="vaccination__item-date">
      {{ vaccine.date }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'VaccinationItem',
  props: {
    vaccine: {
      type: Object,
      required: false,
      default: null
    }
  }
}
</script>
<style scoped lang="scss">

.vaccination__item {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.10);
  padding: 10px;
}

.vaccination__item-type {
  color: #002856;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 7px;
}

.vaccination__item-count {
  color: #002856;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.vaccination__item-date {
  color: #002856;
  font-size: 14px;
  font-weight: 400;
}
</style>
