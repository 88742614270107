<template>
  <!-- Modal -->
  <div class="modal fade2 sidebar" id="sidebarModal" tabindex="-1" aria-labelledby="sidebarModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable sidebar__dialog">
      <div class="modal-content sidebar__content">
        <div class="modal-header sidebar__header">
          <h5 class="modal-title sidebar__title">{{ userFullName }}</h5>
          <IconInCircle bg-color="#FFFFFF" width="32" height="32">
            <img src="@/assets/uploads/images/header/user-icon.svg" alt="иконка пользователя">
          </IconInCircle>
          <button type="button" class="btn-close sidebar__close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <a href="#" class="sidebar__med-card">
            Добавить медицинскую карту
          </a>

          <nav class="sidebar__nav" data-bs-dismiss="modal" aria-label="Close">
            <ul class="sidebar__list">
              <li class="sidebar__item" v-for="link in sidebarLinks1" :key="link.title">
                <SidebarLink :img-name="link.imgName" :path-name="link.pathName" :title="link.title"/>
              </li>
            </ul>

            <ul class="sidebar__list">
              <li class="sidebar__item" v-for="link in sidebarLinks2" :key="link.title">
                <SidebarLink :img-name="link.imgName" :path-name="link.pathName" :title="link.title"/>
              </li>
            </ul>

            <ul class="sidebar__list">
              <li class="sidebar__item" v-for="link in sidebarLinks3" :key="link.title">
                <SidebarLink :img-name="link.imgName" :path-name="link.pathName" :title="link.title"/>
              </li>
            </ul>

            <ul class="sidebar__list">
              <li class="sidebar__item">
                <a href="/auth/phone" @click.prevent="logOut" class="sidebar__link" data-bs-dismiss="modal"
                   aria-label="Close">
                  <img src="@/assets/uploads/images/sidebar/sign-out-option.svg" alt="Выйти">
                  <h5>
                    Выйти
                  </h5>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref, toRefs } from 'vue'
import IconInCircle from '@/components/ui/IconInCircle.vue'
import { useUserStore } from '@/stores/UserStore'
import SidebarLink from '@/components/layout/sidebar/SidebarLink.vue'

export default {
  name: 'TheSidebar',
  components: { SidebarLink, IconInCircle },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },

  setup (props) {
    const sidebarLinks1 = ref([
      {
        imgName: 'home-page',
        title: 'Главная',
        pathName: '/'
      },
      {
        imgName: 'appointment',
        title: 'Запись на прием',
        pathName: '/online-appointment'
      },
      {
        title: 'Мой профиль',
        imgName: 'doctor',
        pathName: '/profile'
      },
      {
        imgName: 'family',
        title: 'Моя семья',
        pathName: '/profile/family'
      },
      {
        imgName: 'document',
        title: 'Медкарта',
        pathName: '/med-card/history'
      },
      {
        imgName: 'doctor',
        title: 'Врачи',
        pathName: '/all-doctors'
      },
      {
        imgName: 'calendar',
        title: 'Календари',
        pathName: '/calendar'
      },
      {
        imgName: 'call-doctor',
        title: 'Вызов врача на дом',
        pathName: '#'
      },
      {
        imgName: 'wallet-filled-money',
        title: 'Финансы',
        pathName: '/finance'
      },
      {
        imgName: 'folder',
        title: 'Мои файлы',
        pathName: '/files'
      },
      {
        imgName: 'checklist',
        title: 'Подготовка к исследованию',
        pathName: '/preparation'
      }
    ])
    const sidebarLinks2 = ref([
      {
        title: 'Акции',
        imgName: 'discount',
        pathName: '/offers'
      },
      {
        title: 'Комплексные программы',
        imgName: 'complexity',
        pathName: '/program/all'
      }
    ])
    const sidebarLinks3 = ref([
      {
        title: 'Оставить отзыв',
        imgName: 'review',
        pathName: '#'
      },
      {
        title: 'Написать в Whatsapp',
        imgName: 'whats-app',
        pathName: '#'
      },
      // {
      //   title: 'Техподдержка',
      //   imgName: 'ask',
      //   pathName: '#'
      // }
      {
        title: 'Паркинг',
        imgName: 'parking',
        pathName: '/parking',
        locked: true
      }
    ])
    const userStore = useUserStore()
    const toast = inject('toast')
    const { user } = toRefs(props)
    const userFullName = computed(() => {
      if (user.value) {
        const middleName = user.value.middle_name
        return `
        ${user.value.last_name}
        ${user.value.first_name[0].toUpperCase()}.
        ${middleName ? middleName[0].toUpperCase() : ''}.`
      }

      return ''
    })

    const showErrorToaster = (path) => {
      if (path !== '/') {
        toast.error('Функционал в разработке', {
          position: 'top-right'
        })
      }
    }

    const logOut = () => {
      userStore.logOut()
    }

    return {
      userFullName,
      logOut,
      sidebarLinks1,
      sidebarLinks2,
      sidebarLinks3,
      showErrorToaster
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  &__dialog {
    margin: 0;
    height: 100%;
  }

  &__content {
    background: #002856;
    color: #fff;
    width: 80%;
    height: 100%;
    border-radius: 0;
  }

  &__header {
    gap: 20px;
    border-bottom: 0;
  }

  &__title {
    font-weight: 600;
  }

  &__close {
    filter: invert(96%) sepia(54%) saturate(0%) hue-rotate(169deg) brightness(104%) contrast(106%);
    opacity: 1;
  }

  &__med-card {
    display: block;
    margin-bottom: 20px;
    font-size: 15px;
    text-decoration: underline;
  }

  &__nav {
  }

  &__list {
    padding-bottom: 15px;
    margin-bottom: 20px;

    &:not(:last-child) {
      border-bottom: 1px solid #fff;
    }
  }

  &__item {
  }

  &__link {
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }

}

.fade2 {
  transform: translateX(-100vw);
  opacity: 0;
  transition: all .2s linear;
  display: block !important;
}

.fade2.show {
  opacity: 1;
  transform: translateX(0);
}
</style>
