<template>
  <div class="analyzes-form">
    <div class="analyzes-form__head">
        <h6 class="analyzes-form__title">
          Тест
        </h6>
    </div>
    <div class="analyzes-form__td">
        <p class="analyzes-form__desc">
          Определение тестестерона общего
        </p>
    </div>
    <div class="analyzes-form__head">
      <h6 class="analyzes-form__title">
        Результат
      </h6>
    </div>
    <div class="analyzes-form__td">
      <p class="analyzes-form__desc">
        0,38
      </p>
    </div>
    <div class="analyzes-form__head">
      <h6 class="analyzes-form__title">
        Норма
      </h6>
    </div>
    <div class="analyzes-form__td">
      <p class="analyzes-form__desc">
        0,02-0,38
      </p>
    </div>
    <div class="analyzes-form__head">
      <h6 class="analyzes-form__title">
        Отклонение
      </h6>
    </div>
    <div class="analyzes-form__td">
      <p class="analyzes-form__desc analyzes-form__desc--danger">
        отклонение от нормы
      </p>
    </div>
    <div class="analyzes-form__head">
      <h6 class="analyzes-form__title">
        Критичность отклонения
      </h6>
    </div>
    <div class="analyzes-form__td">
      <p class="analyzes-form__desc analyzes-form__desc--danger">
        выше нормы (числовой результат
      </p>
    </div>
    <div class="analyzes-form__head">
      <h6 class="analyzes-form__title">
        Единицы измерения
      </h6>
    </div>
    <div class="analyzes-form__td">
      <p class="analyzes-form__desc">
        нг/мл
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalyzesForm'
}
</script>

<style scoped lang="scss">
.analyzes-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;

  &__head {
    border: 1px solid $blue-light;
    padding: 6px 8px;
  }

  &__title {
  }

  &__td {
    padding: 6px 8px;
    border: 1px solid $blue-light;
  }

  &__desc {

    &--danger {
      font-weight: 700;
    }
  }
}
</style>
