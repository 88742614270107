<template>
  <form v-if="!loading" action="" class="form-phone" @submit.prevent="getUserSmsCode">
    <div class="form-phone__group" :class="{ 'form-group--error': v$.phoneUser.$error || error }">
      <OtpForm
          v-model="userInfo.phoneUser"
          :digit-count="10"
          isPhone
          :error="v$.phoneUser.$error || error"
          @is-completed="error = false"
      />

      <div v-if="v$.phoneUser.$error || error" class="error help-block">
        Необходимо ввести телефон
      </div>
    </div>

    <div v-if="responseError" class="error help-block">
      {{ responseErrorMsg }}
    </div>

    <h4 class="form-phone__label form-phone__sms">
      На этот номер придет смс с кодом
    </h4>

    <CustomButton tag="button" type="submit" marginBottom="20px">
      Получить код
    </CustomButton>

    <a href="https://www.k31.ru/upload/doc/useterms.pdf" target="_blank" class="form-phone__rules">
      Пользовательское соглашение
    </a>

    <router-link to="/">
      <p style="text-align: center">
        <br>Вернуться
      </p>
    </router-link>
  </form>

  <TheLoader v-if="loading"/>
</template>

<script>
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { required } from '@vuelidate/validators'
import OtpForm from '@/components/pages/auth/OtpForm.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import axios from 'axios'
import { useVuelidate } from '@vuelidate/core'
import { reactive, ref, watch } from 'vue'

export default {
  name: 'PhoneForm',
  emits: ['setCode'],
  components: { TheLoader, OtpForm, CustomButton },
  setup (props, { emit }) {
    const loading = ref(false)
    const error = ref(false)
    const responseError = ref(false)
    const responseErrorMsg = ref('Сервис временно недоступен. Повторите попытку позже')
    const userInfo = reactive({
      phoneUser: ''
    })
    const rules = {
      phoneUser: { required }
    }
    const v$ = useVuelidate(rules, userInfo)

    const getUserSmsCode = async () => {
      v$.value.$touch()
      if (v$.value.$error || userInfo.phoneUser.length !== 10) {
        error.value = true
        return
      }
      error.value = false

      loading.value = true
      try {
        const response = await axios.post('/v2/element/user/request-otp', {
          phone: `+7${userInfo.phoneUser}`
        })

        if (response.data.status === 'ok') {
          emit('setCode', `+7${userInfo.phoneUser}`)
        } else {
          if (response.data.message) {
            responseErrorMsg.value = response.data.message
          } else {
            responseErrorMsg.value = 'Произошла ошибка'
          }

          responseError.value = true
        }
      } catch (e) {
        error.value = true
      } finally {
        loading.value = false
      }
    }

    watch(() => userInfo.phoneUser, () => {
      if (userInfo.phoneUser.length === 12) {
        v$.value.$touch()
        error.value = false
      }
    })

    return {
      userInfo,
      getUserSmsCode,
      v$,
      error,
      responseError,
      loading,
      responseErrorMsg
    }
  }
}
</script>

<style scoped lang="scss">
.form-phone {
  &__group {

    & .help-block {
      margin-bottom: 12px;
    }
  }

  &__label {
    color: $blue;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 5px;

    &.error {
      color: $red;
    }
  }

  &__inputs {
    margin-bottom: 12px;
  }

  &__sms {
    margin-bottom: 25px;
  }

  &__rules {
    display: block;
    text-align: center;
    color: $blue;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.3px;
  }
}

</style>
