import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '@/views/auth/RegisterView.vue'
import RegisterOptionsView from '@/views/auth/RegisterOptionsView.vue'
import RegisterNewView from '@/views/auth/RegisterNewView.vue'
import MedCardView from '@/views/med-card/MedCardView.vue'
import MyScheduleView from '@/views/MyScheduleView.vue'
import MyAnalyzesView from '@/views/MyAnalyzesView.vue'
import FingerView from '@/views/FingerView.vue'
import LoginPhoneView from '@/views/auth/LoginPhoneView.vue'
import AuthView from '@/views/auth/AuthView.vue'
import LoginMembersView from '@/views/auth/LoginMembersView.vue'
import OffersView from '@/views/offers/OffersView.vue'
import OneOffersView from '@/views/offers/one-offers/OneOffersView.vue'
import FinanceView from '@/views/FinanceView.vue'
import PreparationView from '@/views/PreparationView.vue'
import FilesView from '@/views/FilesView.vue'
import { getCookie } from '@/utilits/getCookie'
import CalendarView from '@/views/calendar/CalendarView.vue'
import AppointmentsCalendarView from '@/views/calendar/AppointmentsCalendarView.vue'
import PregnancyCalendarView from '@/views/calendar/PregnancyCalendarView.vue'
import VaccinationCalendarView from '@/views/calendar/VaccinationCalendarView.vue'
// import MedCardResearchView from '@/views/med-card/MedCardResearchView.vue'
// import MedCardHistoryView from '@/views/med-card/MedCardHistoryView.vue'
// import MedCardAnalyzesView from '@/views/med-card/MedCardAnalyzesView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    redirect: '/auth/phone',
    component: LoginView
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    redirect: '/auth/phone',
    children: [
      {
        path: '/auth/phone',
        name: 'auth-phone',
        component: LoginPhoneView
      },
      {
        path: '/auth/members',
        name: 'auth-members',
        component: LoginMembersView
      }
    ]
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile/ProfileView.vue'),
    redirect: '/profile/details',
    children: [
      {
        path: '/profile/details',
        name: 'profile-details',
        component: () =>
          import('../views/profile/children/ProfileDetailsView.vue')
      },
      {
        path: '/profile/family',
        name: 'profile-family',
        component: () =>
          import('../views/profile/children/ProfileFamilyView.vue')
      },
      {
        path: '/profile/programs',
        name: 'profile-programs',
        component: () =>
          import('../views/profile/children/ProfileProgramsView.vue')
      }
    ]
  },
  {
    path: '/profile/add-medcard',
    name: 'add-medcard',
    component: () =>
      import('../views/profile/add-medcaed/ProfileAddMedCardView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/register',
    component: RegisterView,
    redirect: '/register/options',
    children: [
      {
        name: 'register-options',
        path: 'options',
        component: RegisterOptionsView
      },
      {
        name: 'register-new',
        path: 'new',
        component: RegisterNewView
      }
    ]
  },
  {
    path: '/med-card',
    component: MedCardView,
    name: 'med-card'
    // children: [
    //   {
    //     name: 'med-card-history',
    //     path: 'history',
    //     component: MedCardHistoryView
    //   },
    //   {
    //     name: 'med-card-research',
    //     path: 'research',
    //     component: MedCardResearchView
    //   },
    //   {
    //     name: 'med-card-analyzes',
    //     path: 'analyzes',
    //     component: MedCardAnalyzesView
    //   }
    // ]
  },
  {
    path: '/my-analyzes/:id/:resourceId',
    name: 'my-analyzes',
    component: MyAnalyzesView
  },
  {
    path: '/my-schedule/:id/:resourceId',
    name: 'my-schedule',
    component: MyScheduleView
  },
  {
    path: '/programs',
    component: () => import('../views/programs/ProgramsView.vue'),
    name: 'program',
    redirect: '/program/my',
    children: [
      {
        path: '/program/my',
        name: 'program-my',
        component: () => import('../views/programs/MyProgramsView.vue')
      },
      {
        path: '/program/all',
        name: 'program-all',
        component: () => import('../views/programs/AllProgramsView.vue')
      }
    ]
  },

  {
    path: '/doctors',
    component: () => import('../views/doctors/DoctorsView.vue'),
    name: 'doctors',
    redirect: '/doctors/all-doctors',
    children: [
      {
        path: '/doctors/all-doctors',
        name: 'all-doctors',
        component: () => import('../views/doctors/AllDoctorsView.vue')
      },
      {
        path: '/doctors/departments',
        name: 'doctors-departments',
        component: () => import('../views/doctors/DoctorsDepartmentsView.vue')
      },
      {
        path: '/doctors/departments/:department',
        name: 'selected-department',
        component: () => import('../views/doctors/SelectedDepartmentView.vue')
      },
      {
        path: '/doctors/:doctor',
        name: 'selected-doctor',
        component: () => import('../views/doctors/SelectedDoctorView.vue')
      }
    ]
  },

  {
    path: '/online-appointment',
    name: 'online-appointment',
    component: () =>
      import('../views/online-appointment/OnlineAppointmentView.vue')
  },
  // {
  //   path: '/support',
  //   name: 'support',
  //   component: () =>
  //     import('../views/support/SupportView.vue'),
  //   redirect: '/support/form',
  //   children: [
  //     {
  //       path: '/support/form',
  //       name: 'support-form',
  //       component: () =>
  //         import('../views/support/children/SupportFormView.vue')
  //     },
  //     {
  //       path: '/support/requests',
  //       name: 'support-requests',
  //       component: () =>
  //         import('../views/support/children/SupportRequestsView.vue')
  //     }
  //   ]
  // },
  {
    path: '/parking',
    name: 'parking',
    component: () =>
      import('../views/ParkingView.vue')
  },
  {
    path: '/online-consultation',
    name: 'online-consultation',
    component: () =>
      import('../views/online-consultation/OnlineConsultationView.vue')
  },
  {
    path: '/agreement-appointment',
    name: 'agreement-appointment',
    component: () =>
      import('../views/online-consultation/AgreementAppointment.vue')
  },
  {
    path: '/finance',
    name: 'finance',
    component: FinanceView
  },
  {
    path: '/call-doctor',
    name: 'call-doctor',
    component: () => import('../views/call-doctor/CallDoctorView.vue')
  },
  {
    path: '/preparation',
    name: 'preparation',
    component: PreparationView
  },
  {
    path: '/files',
    name: 'files',
    component: FilesView
  },
  {
    path: '/offers',
    name: 'offers',
    component: OffersView
  },
  {
    path: '/offers/:alias',
    name: 'one-offers',
    component: OneOffersView
  },
  {
    path: '/index.html',
    name: 'finger',
    component: FingerView
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarView
  },
  {
    path: '/calendar-appointments',
    name: 'calendar-appointments',
    component: AppointmentsCalendarView
  },
  {
    path: '/calendar-pregnancy',
    name: 'calendar-pregnancy',
    component: PregnancyCalendarView
  },
  {
    path: '/calendar-vaccination',
    name: 'calendar-vaccination',
    component: VaccinationCalendarView
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: () => import('../views/ErrorView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    // Если есть hash, прокручиваем к элементу по нему
    if (to.hash) {
      return { el: to.hash }
    }

    // Если есть savedPosition, возвращаем его же (переход назад/вперёд)
    if (savedPosition) {
      return savedPosition
    }

    // Если оба маршрута в мета свойствах имеют saveScrollPosition - не меняем положение
    if (to.meta.saveScrollPosition && from.meta.saveScrollPosition) {
      return false
    }

    // По умолчанию возвращаемся в начало
    return { left: 0, top: 0 }
  },

  routes
})

router.beforeEach(async (to, from) => {
  const token = getCookie('user_token')
  const protectedRoutes = [
    'online-appointment',
    'med-card',
    'all-doctors',
    'finance',
    'files',
    'profile',
    'profile-details',
    'profile-family',
    'feedback',
    'all-doctors',
    'doctors-departments',
    'selected-department',
    'selected-doctor',
    'call-doctor',
    'calendar',
    'agreement-appointment',
    'online-consultation'
  ]

  const authPath = [
    'auth',
    'auth-phone',
    'auth-members',
    'register-options',
    'register-new'
  ]

  if (token && authPath.includes(to.name)) {
    return false
  }

  if (!token && protectedRoutes.includes(to.name)) {
    return { name: 'auth' }
  }

  if (to.name === 'finger' && from.name === 'home') {
    return false
  }
})

export default router
