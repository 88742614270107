<template>
  <div :class="{container: container}">
    <div class="arrow-title" :class="{
    'arrow-title--dark': darkTitle
  }">
      <div class="arrow-title__back">
        <img @click="$router.go(-1)"
             class="arrow-title__img"
             src="@/assets/uploads/icon/common/arrow-back.svg"
             alt="стрелка">
      </div>
      <h3>{{ title }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArrowTitle',
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    darkTitle: {
      type: Boolean,
      default: false,
      required: false
    },
    marginBottom: {
      type: String,
      default: '8px',
      required: false
    },
    container: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  @include desktop {
    padding: 0;
  }
}

.arrow-title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 10px;
  margin-bottom: v-bind('marginBottom');

  & h1, h2, h3, h4, h5, h6 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    justify-self: center;
    @include desktop {
      color: #002856;
      font-size: 22px;
    }
  }
  &__img {
    filter: invert(97%) sepia(1%) saturate(501%) hue-rotate(197deg) brightness(116%) contrast(100%);

  }
  @include desktop {
    &__img {
      filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg) brightness(102%) contrast(103%);
    }
  }
  &--dark {
    & h1, h2, h3, h4, h5, h6 {
      color: #082B53;
    }

    img {
      filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg) brightness(102%) contrast(103%);
    }
  }
}

.arrow-title__back {
  cursor: pointer;
}
</style>
