<template>
  <ArrowTitle title="Подготовка к исследованиям"></ArrowTitle>
  <SectionWrapper stretched>
    <div class="preparation__list">
      <ul>
        <li v-for="item in preparationList" :key="item.id" @click.prevent='selectedIndex = item.title'
            :class='{"selected": (item.title === selectedIndex)}'>
          {{ item.title }}
        </li>
      </ul>
    </div>
    <div
        class="preparation__files"
        v-for="item in preparationDisplay"
        :key="item.title"
    >
      <h3 class="preparation__subtitle">{{ item.title }}</h3>
      <ul class="preparation__files-list">
        <li
            v-for="listItem in item.files"
            :key="listItem.id"
        >
          <img src="@/assets/uploads/icon/common/file.svg" alt="файл">
          <a
              :href="`${listItem.url}`"
              target="_blank"
              class="preparation__item-link"
          >
            {{ listItem.title }}
          </a>
        </li>
      </ul>
    </div>
  </SectionWrapper>
</template>

<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import { computed, onMounted, ref } from 'vue'
import axios from 'axios'

export default {
  name: 'PreparationView',
  components: { ArrowTitle, SectionWrapper },
  props: {
    user: {
      type: Object,
      required: true,
      default: null
    }
  },
  setup () {
    const preparationList = ref(null)
    const selectedIndex = ref('Диагностика')
    const fetchPreparationList = async () => {
      try {
        const response = await axios.get('/v1/study')

        if (response.data.status === 'ok') {
          preparationList.value = response.data.data
        } else {
          return null
        }
      } catch (err) {
        throw new Error()
      }
    }
    const preparationDisplay = computed(() => {
      if (preparationList.value) {
        return preparationList.value.filter((el) => el.title === selectedIndex.value)
      }
      return null
    })
    onMounted(async () => {
      await fetchPreparationList()
    })
    return {
      preparationList,
      preparationDisplay,
      selectedIndex
    }
  }
}
</script>

<style scoped lang="scss">
.preparation__list {
  @include desktop {
    margin-top: 24px;
  }
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 12px;

    li {
      color: #062E4F;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      border-radius: 30px;
      background: #E1E8F4;
      padding: 8px 16px;
      transition: 0.2s;
      cursor: pointer;
      @include desktop {
        font-size: 16px;
        background: #fff;
      }
      &.selected {
        background: #002856;
        color: #FFF;
      }
    }
  }
}

.preparation__files {
  margin-top: 24px;
}

.preparation__subtitle {
  color: #002856;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.36px;
  margin-bottom: 16px;
  @include desktop {
    font-size: 20px;
    margin-bottom: 24px;
  }
}

.preparation__files-list {
  row-gap: 17px;
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    align-items: flex-start;

    img {
      margin-right: 12px;
    }

    a {
      color: #002856;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.28px;
      @include desktop {
        font-size: 16px;
      }
    }
  }
}
</style>
