<template>
  <TheTitle no-margin>
    <h3>
      Вход в личный кабинет пациента
    </h3>
  </TheTitle>

  <!--  <SecCode :value="userPhone"-->
  <!--           :inputLength="10"-->
  <!--           :prepend="'+7'"-->
  <!--           key="phone"-->
  <!--           @input="userPhone = $event"-->
  <!--  />-->

  <!--  <TestOTP/>-->

  <PhoneForm v-if="!userPhone" @set-code="userPhone = $event"/>

  <SmsForm v-if="userPhone" :userPhone="userPhone"/>

  <div v-if="isBrowser" class="download">
    <a
        target="_blank"
        href="https://apps.apple.com/app/id1508496917"
        class="login__app-link"
    >
      <img src="@/assets/uploads/images/register/app-store.jpg" alt="Скачать из AppStore"/>
    </a>
    <a
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.k31.lk"
        class="login__app-link"
    >
      <img src="@/assets/uploads/images/register/google-play.jpg" alt="Скачать из Google Play"/>
    </a>
  </div>
</template>

<script>
import TheTitle from '@/components/ui/typography/TheTitle.vue'
import PhoneForm from '@/components/pages/auth/PhoneForm.vue'
import SmsForm from '@/components/pages/auth/SmsForm.vue'
import { computed, ref } from 'vue'
// import TestOTP from '@/components/pages/auth/TestOTP.vue'
// import SecCode from '@/components/pages/auth/SecCode.vue'

export default {
  name: 'LoginPhoneView',
  // components: { SecCode, TestOTP, TheTitle, PhoneForm, SmsForm },
  components: { TheTitle, PhoneForm, SmsForm },
  setup () {
    const userPhone = ref('')

    const isBrowser = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform !== 'ios' && platform !== 'android'
    })

    return {
      userPhone,
      isBrowser
    }
  }
}
</script>

<style scoped>
.download {
  display: flex;
  gap: 15px;
  align-items: center;
}

</style>
