<template>
  <form v-if="!loading" action="" class="form-sms" @submit.prevent="getUserSmsCode">

    <div class="form-sms__group" :class="{ 'form-group--error': v$.code.$error || error }">
      <OtpForm
          v-model="userInfo.code"
          :digit-count="6"
          :error="v$.code.$error || error"
          @is-completed="error = false"
      />

      <div v-if="v$.code.$error || error" class="error help-block">
        Необходимо ввести смс код
      </div>
      <div v-if="responseError" class="error help-block">
        Введеный код неверен
      </div>
    </div>

    <CustomButton tag="button" type="submit" marginBottom="20px">
      Войти
    </CustomButton>

    <a href="https://www.k31.ru/upload/doc/useterms.pdf" target="_blank" class="form-sms__rules">
      Пользовательское соглашение
    </a>
  </form>

  <TheLoader v-if="loading"/>
</template>

<script>
import { inject, reactive, ref, toRefs, watch } from 'vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import { useAuthUser } from '@/composables/useAuthUser'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { showFingerPrint } from '@/utilits/showFingerPrint'
import OtpForm from '@/components/pages/auth/OtpForm.vue'
import TheLoader from '@/components/ui/TheLoader.vue'

export default {
  name: 'SmsForm',
  components: { TheLoader, OtpForm, CustomButton },
  props: {
    userPhone: {
      type: String,
      required: true,
      default: ''
    }
  },
  setup (props) {
    const loading = ref(false)
    const { userPhone } = toRefs(props)
    const error = ref(false)
    const router = useRouter()
    const responseError = ref(false)
    const userStore = useUserStore()
    const { setUserCards, setUserSmsCode, setUserPhone } = userStore
    const { userToken } = storeToRefs(userStore)
    const toast = inject('toast')
    const userInfo = reactive({
      code: ''
    })
    const rules = {
      code: { required }
    }
    const v$ = useVuelidate(rules, userInfo)
    const showHelloToaster = () => {
      toast.success('Добро пожаловать!', {
        position: 'top-right',
        type: 'success'
      })
    }
    const getUserSmsCode = async () => {
      v$.value.$touch()
      if (v$.value.$error || userInfo.code.length !== 6) {
        error.value = true
        return
      }
      error.value = false

      try {
        loading.value = true
        const response = await axios.post('/v2/element/user/auth-otp', {
          phone: userPhone.value,
          code: userInfo.code
        })

        if (response.data.status === 'ok') {
          const action = response.data.data.action

          if (action === 'login') {
            await useAuthUser(userPhone.value, userInfo.code)
            responseError.value = true
            await router.push({ name: 'home' })
            showHelloToaster()
          }

          if (action === 'choose') {
            const cards = response.data.data.cards
            setUserCards(cards)
            setUserSmsCode(userInfo.code)
            setUserPhone(userPhone.value)
            await router.push({ name: 'auth-members' })
          }

          if (action === 'signup') {
            setUserSmsCode(userInfo.code)
            setUserPhone(userPhone.value)
            await router.push({ name: 'register-new' })
          }
        } else {
          responseError.value = true
          return
        }
      } catch (e) {
        error.value = true
        return
      } finally {
        loading.value = false
      }

      if (userToken.value) {
        showFingerPrint(userToken.value)
      }
    }

    watch(() => userInfo.code, () => {
      if (userInfo.code.length === 6) {
        v$.value.$touch()
        error.value = false
      }
    })

    return {
      userInfo,
      getUserSmsCode,
      v$,
      error,
      responseError,
      loading
    }
  }
}
</script>

<style scoped lang="scss">
.form-sms {
  width: 100%;
  max-width: 280px;
  &__group {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    & .help-block {
    }
  }

  &__label {
    text-align: center;
    color: $blue;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 5px;

    &.error {
      color: $red;
    }
  }

  &__inputs {
    justify-content: center;
    margin-bottom: 25px;
  }

  &__sms {
    margin-bottom: 25px;
  }

  &__rules {
    display: block;
    text-align: center;
    color: $blue;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.3px;
  }
}

</style>
