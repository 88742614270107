<template>
  <router-link class="actual-elem" :to="href ? href : '/'">
    <div class="actual-elem__wrapper"
    >
      <div class="actual-elem__picture" :style="{ backgroundImage: 'url(' + img + ')' }">
      </div>
    </div>

    <div class="actual-elem__text">
      {{ text }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ActualSwiperElement',
  props: ['img', 'text', 'href']
}
</script>

<style scoped lang="scss">
.actual-elem {
  display: block;
  max-width: 120px;

  &__wrapper {
    margin-bottom: 6px;
    border: 1px solid #F1842C;
    border-radius: 8px;
    padding: 2px;
  }
}
</style>

<style lang="scss">
.actual-elem {

  &__wrapper {
    padding: 2px;
  }

  &__picture {
    min-width: 114px;
    min-height: 114px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
  }
}

.actual-elem__text {
  color: #FFF;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  @include desktop {
    color: #002856;
    font-weight: 400;
  }
}
</style>
