<template>
  <div class="block-price">
    <div class="block-price__title">Стоимость</div>
    <ul>
      <li v-for="item in data.json.prices" :key="item.id">
        <div class="block-price__name">{{item.title}}</div>
        <div class="block-price__costs">
          <div class="block-price__costs-new">{{item.costs[0]}}</div>
          <div class="block-price__costs-old">{{item.costs[1]}}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'BlockPrice',
  props: ['data']
}
</script>
<style lang="scss" scoped>
.block-price {
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 16px 12px;
  ul {
    li {
      margin-bottom: 8px;
      &:not(:last-child) {
        padding-bottom: 8px;
        border-bottom: 1px solid #E1E8F4;
      }
      @include desktop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
.block-price__name {
  color: #062E4F;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  @include desktop {
    font-size: 16px;
  }
}
.block-price__title {
  color: #062E4F;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;
}
.block-price__costs {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #002358;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 6px;
  .block-price__costs-old {
    text-decoration: line-through;
    white-space: nowrap;
  }
  .block-price__costs-new {
    white-space: nowrap;
  }
}
</style>
