<template>
  <footer class="footer" :style="{paddingBottom: footerPadding}">
    <div class="container footer__container">
      <!--      <Teleport to="main">-->
      <!--        <TheMenu :active="menuIsActive" @toggle-menu="menuIsActive = !menuIsActive"/>-->
      <!--      </Teleport>-->

      <Teleport to="main">
        <!-- use the modal component, pass in the prop -->
        <TheMenu2 :show="menuIsActive" @close="closeMenu">
          <template #header>
            <h3>custom header</h3>
          </template>
        </TheMenu2>
      </Teleport>
      <!--      <button class="footer__menu" type="button" data-bs-toggle="modal" data-bs-target="#sidebarModal">-->
      <!--        <img src="@/assets/uploads/images/footer/dots.svg" alt="точки меню">-->
      <!--        <span>-->
      <!--            Меню-->
      <!--          </span>-->
      <!--      </button>-->

      <nav class="footer__nav">
        <ul class="footer__list" @click="closeMenu">
          <li class="footer__item">
            <router-link to="/" class="footer__link">
              <img src="@/assets/uploads/images/sidebar/home-page.svg" alt="Главная">
              <span>
                Главная
              </span>
            </router-link>
          </li>
          <li class="footer__item">
            <router-link to="/doctors" class="footer__link">
              <img src="@/assets/uploads/images/footer/doctor-footer.svg" alt="картинка доктора">
              <span>
                Врачи
              </span>
            </router-link>
          </li>

          <li class="footer__item">
            <router-link to="/med-card" class="footer__link">
              <img src="@/assets/uploads/images/footer/med-book.svg" alt="Медкарта">
              <span>
                Медкарта
              </span>
            </router-link>
          </li>

          <li class="footer__item">
            <router-link to="/profile/details" class="footer__link">
              <img src="@/assets/uploads/images/sidebar/profile.svg" alt="Профиль">
              <span>
                Профиль
              </span>
            </router-link>
          </li>
        </ul>
      </nav>
      <button class="footer__menu" @click="toggleMenu">
        <img src="@/assets/uploads/images/footer/dots.svg" alt="точки меню">
      </button>
    </div>
  </footer>
</template>

<script>
import { inject } from 'vue'
import TheMenu2 from '@/components/layout/menu/TheMenu2.vue'
import { useMenuStore } from '@/stores/MenuStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'TheFooter',
  components: { TheMenu2 },
  props: {
    footerPadding: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup () {
    const menuStore = useMenuStore()
    const { closeMenu, toggleMenu } = menuStore
    const { menuIsActive } = storeToRefs(menuStore)
    const toast = inject('toast')

    const showErrorToaster = () => {
      toast.error('Функционал в разработке', {
        position: 'top-right'
      })
    }

    return {
      showErrorToaster,
      menuIsActive,
      closeMenu,
      toggleMenu
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  background: #FFFFFF;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.20);
  border-radius: 6px 6px 0px 0px;
  @include desktop {
    display: none;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 0;
  }

  &__menu {
    display: flex;
    align-items: center;
    background: $blue;
    border-radius: 8px;
    border: unset;
    font-size: 12px;
    line-height: 11px;
    color: #FFFFFF;
    transition: all .2s ease-in-out;
    width: 40px;
    height: 40px;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 10px;
    //&:hover {
    //  background: #BAC7DE;
    //  color: #002856;
    //}

    //&:active {
    //  background: #BAC7DE;
    //  color: #002856;
    //}
  }

  &__nav {
    flex: 1 1 auto;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__item {
    span {
      white-space: nowrap;
    }
  }

  &__link {
    position: relative;
    display: flex;
    padding: 12px 0px;
    width: 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    max-width: 75px;
    font-weight: 700;
    font-size: 8px;
    line-height: 9px;
    text-align: center;
    color: #7F8DA9;
    z-index: 2;

    &:before {
      content: '';
      position: absolute;
      display: none;
      top: -7px;
      left: 0;
      right: 0;
      bottom: 0px;
      border-radius: 6px 6px 0px 0px;
      background: #E1E8F4;
      width: 100%;
      z-index: -1;
    }

    &.router-link-active {
      background: #E1E8F4;
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.10);
      color: $blue;
      border-radius: 6px 6px 0px 0px;

      &:before {
        display: block;
      }
    }

    &.router-link-active img {
      filter: invert(12%) sepia(52%) saturate(2457%) hue-rotate(192deg) brightness(102%) contrast(108%);

    }

    &:hover img {
      filter: invert(12%) sepia(52%) saturate(2457%) hue-rotate(192deg) brightness(102%) contrast(108%);
    }

    &:hover {
      color: #002856;
    }

    &:active {
      color: #002856;
    }

    & img {
      max-width: 20px;
      width: 100%;
      filter: invert(58%) sepia(21%) saturate(414%) hue-rotate(182deg) brightness(92%) contrast(87%);
    }
  }
}
</style>
