<template>
  <div class="block-rate">
    <div class="block-rate__about">
      <div class="block-rate__title">{{ data.json.title }}</div>
      <div class="block-rate__text" v-if="data.json.description" v-html="data.json.description">
      </div>
    </div>
    <div class="block-rate__content">
      <div class="block-rate__subtitle">Рейтинг клиник К+31</div>
      <div class="block-rate__list">
        <div class="block-rate__item" v-for="item in data.json.rates" :key="item.id">
          <div class="block-rate__item-top">
            <div class="block-rate__item-logo">
              <img :src="item.img" alt="">
            </div>
            <div class="block-rate__item-address">
              {{ item.rates.title }}
            </div>
          </div>
          <div class="block-rate__wrap">
            <div class="block-rate__num"> {{ item.rates.rate }}</div>
            <div class="block-rate__stars">
              <ul>
                <li v-for='i in 5' :key="i">
                  <img v-if='item.rates.rate >= i' src="@/assets/uploads/icon/common/star.svg" alt="star">
                  <img v-else src="@/assets/uploads/icon/common/star-half-stroke.svg" alt="star">
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BlockRate',
  props: ['data']
}
</script>

<style lang="scss">
.block-rate {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.block-rate__list {
  @include desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 80px;
  }
}
.block-rate__title {
  color: #062E4F;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;
  @include desktop {
    font-weight: 500;
  }
}

.block-rate__text {
  color: #54678D;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  @include desktop {
    font-size: 16px;
    line-height: 25px;
  }
  small {
    color: #062E4F;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  ul {
    overflow: visible;
    margin-left: 10px;
    margin-bottom: 10px;
    @include desktop {
      margin-left: 20px;
    }
    li {
      list-style: disc;
    }
  }
}

.block-rate__content {
  padding: 14px 12px;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  @include desktop {
    padding: 25px 16px;
  }
}

.block-rate__about {
  @include desktop {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    padding: 16px;
  }
}

.block-rate__subtitle {
  color: #002856;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.36px;
  margin-bottom: 18px;
  @include desktop {
    font-size: 20px;
  }
}

.block-rate__item-top {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}
.block-rate__wrap {
  display: flex;
  align-items: center;
  gap: 16px;
}
.block-rate__num {
  color: #222;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.block-rate__stars {
  ul {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.block-rate__item {
  margin-bottom: 16px;
  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid #BAC7DE;
  }
  @include desktop {
    &:nth-child(3) {
      border-bottom: 0;
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.block-rate__item-address {
  color: #002856;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;
  white-space: nowrap;
}
</style>
