<template>
  <div
    class="form-group"
    :class="{ 'form-group--error': validError }"
  >
    <div class="form-group__top">
      <label v-if="labelTitle" :for="nameId">{{ labelTitle }}</label>
      <div
        v-if="validError"
        class="error help-block"
      >
        {{ errorMsg }}
      </div>
    </div>
    <input
      :disabled="disabled"
      :type="type"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value.trim() )"
      :placeholder="placeholder"
      class="form-group__input"
      :class="{
          'validation-error': validError,
          'disabled': disabled
        }"
      :name="nameId"
      autocomplete="on"
    />

  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    validError: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMsg: {
      type: String,
      required: false,
      default: 'Необходимо заполнить поле.'
    },
    modelValue: {
      type: [String, null],
      required: true,
      default: null
    },
    nameId: {
      type: String,
      required: true
    },
    labelTitle: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">

.form-group {
  display: flex;
  gap: 5px;
  flex-direction: column;

  &__input {
    padding: 12px;
    background-color: #FFFFFF;
    border: 1px solid #BAC7DE;
    border-radius: 8px;

    &::placeholder {
      opacity: 0.5;
    }

    &.disabled {
      background-color: #ebebeb;
      background-size: 15px 15px;
      background-repeat: no-repeat;
      background-position: 98% center;
      background-image: url('~@/assets/uploads/icon/common/padlock.svg');

      &::placeholder {
        opacity: 0.5;
      }
    }

    &:focus {
      border: 1px solid #002856;
    }

    &::placeholder {
      color: $blue !important;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      color: #062E4F;
      font-size: 16px;
      font-weight: 350;
    }
  }
}

.help-block.error {
  font-size: 12px;
  color: #FF1F00;
}

.validation-error {
  border-color: #FF1F00;
  color: #FF1F00;

  &:focus {
    color: $blue;
  }
}

.validation-error::placeholder {
  color: #FF1F00;
}
</style>
