<template>
  <section class="my-schedule">
    <SectionWrapper class="my-schedule__wrapper">
      <div v-if="!loading" class="my-schedule__wrapper">
        <ArrowTitle :title="doctorType ? doctorType : ''" :dark-title="true" marginBottom="32px"
                    :container="false"></ArrowTitle>
        <div class="my-schedule__content">
          <div class="my-schedule__middle">
            <div class="my-schedule__picture">
              <img
                  :src="doctorImg"
                  :alt="doctorName"
              />
            </div>
            <div class="my-schedule__doc-info">
              <h4 class="my-schedule__doc-title">{{ doctorName }}</h4>
              <p class="my-schedule__doc-desc">
                {{ doctorType }}
              </p>
            </div>
            <div class="my-schedule__date">
              <div class="my-schedule__time">
                <img
                    src="@/assets/uploads/images/home-page/my-appointment/clock.svg"
                    alt="картинка часов"
                />
                <p class="my-schedule__clock">{{ timeAppointment }}</p>
              </div>
              <p class="my-schedule__date-full">{{ dateAppointment }}</p>
            </div>
          </div>

          <div
              class="medcard__content-block"
              v-if="scheduleInfo && scheduleInfo.diagnoses_names">
            <span class="medcard__subtitle">Диагнозы:</span>
            <span v-for="(value, key) in scheduleInfo.diagnoses_names" :key="key">
            {{ value }}{{ key === scheduleInfo.diagnoses_names.length - 1 ? '' : ',' }}
          </span>
          </div>

          <template v-if="scheduleInfo && scheduleInfo.diags && scheduleInfo.diags.content !== null">
            <div class="medcard__subtitle">{{ scheduleInfo.diags.title }}</div>
            <div class="medcard__inner-block">
              <div
                  class="medcard__main"
                  v-html="scheduleInfo.diags.content.title"
              ></div>
              <div v-html="scheduleInfo.diags.content.value"></div>
            </div>
            <template v-if="scheduleInfo.diags.content.length > 0">
              <div
                  class="medcard__inner-block"
                  v-for="item in scheduleInfo.diags.content"
                  :key="item.id"
              >
                <div class="medcard__main" v-html="item.title"></div>
                <div v-html="item.value"></div>
              </div>
            </template>
          </template>

          <template v-if="scheduleInfo && scheduleInfo.details">
            <div
                v-for="item in scheduleInfo.details"
                :key="item.id"
            >
              <div v-if="item.content && item.content !== null" class="medcard__content-block">
                <div class="medcard__subtitle" v-html="item.title"></div>
                <div class="medcard__inner-block">
                  <div
                      class="medcard__main"
                      v-html="item.content.title"
                  ></div>
                  <div v-html="item.content.value"></div>
                </div>
                <template v-if="item.content.length > 0">
                  <div
                      class="medcard__inner-block"
                      v-for="scheduleInfo in item.content"
                      :key="scheduleInfo.id"
                  >
                    <div class="medcard__main" v-html="scheduleInfo.title"></div>
                    <div v-html="scheduleInfo.value"></div>
                  </div>
                </template>
              </div>
            </div>
          </template>

          <template v-if="scheduleInfo?.file && scheduleInfo.file.length !== 0">
            <div class="medcard__content-block">
              <div class="medcard__subtitle">Документы</div>
              <div class="medcard__links">
                <a
                    :href="`${item.url}`"
                    class="medcard__link medcard__link--download"
                    target="_blank"
                    v-for="item in scheduleInfo.file"
                    :key="item.id"
                >
                  <div class="medcard__content-img">
                    <!--                  <img src="~/assets/pictures/doc-preview.jpg" alt=""/>-->
                  </div>
                  <div
                      class="medcard__link-title"
                      v-html="item.description"
                  ></div>
                  <p>Скачать</p>
                </a>
              </div>
            </div>
          </template>

          <div class="medcard__content-block" v-if="scheduleInfo?.next_visit">
            <div class="medcard__subtitle">Следующий визит</div>
            <div v-html="scheduleInfo.next_visit"></div>
          </div>

          <div v-if="true">
            <div v-if="scheduleInfo?.dicom.length !== 0" class="my-schedule__btns">
              <CustomButton type="button" tag="button" @click="openDicom(scheduleInfo?.dicom[0])">
                <template #img>
                  <img
                      src="@/assets/uploads/images/med-card/download.svg"
                      alt="знак скачивания"
                  />
                </template>
                <template #default>
                  <p>
                    Открыть
                  </p>
                </template>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>

      <ModalWrapper :modal-is-open="modalIsOpen" @modal-close="modalIsOpen = false">
        <p class="modal-text mb-1">
          Приложение просмотра снимков
        </p>

        <a :href="'javascript:window.open(\'' + dicomUrl + '\',\'_blank\')'" class="btn btn-primary">Запустить</a>

      </ModalWrapper>

      <TheLoader v-if="loading"/>
    </SectionWrapper>
  </section>
</template>

<script>
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useDateStore } from '@/stores/date'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import ModalWrapper from '@/components/Common/ModalWrapper.vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { computed, inject, onActivated, onMounted, ref } from 'vue'

export default {
  name: 'MyAppointment',
  components: { ModalWrapper, ArrowTitle, TheLoader, CustomButton, SectionWrapper },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },

  setup () {
    const router = useRouter()
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const route = useRoute()
    const scheduleInfo = ref(null)
    const loading = ref(false)
    const dicomUrl = ref('')
    const modalIsOpen = ref(false)
    const dateStore = useDateStore()
    const dictMonthRod = dateStore.dictMonthRod
    const toast = inject('toast')

    const showErrorToaster = () => {
      toast.error('Функционал в разработке', {
        position: 'top-right'
      })
    }

    const geScheduleInfo = async () => {
      try {
        loading.value = true
        const response = await axios.get(
            `/v2/element/patient-history/view/?user_id=${getUserId.value}&id=${route.params.id}`,
            {
              headers: {
                Authorization: `Bearer ${userToken.value}`
              }
            }
        )

        if (response.data.status === 'ok') {
          scheduleInfo.value = response.data.data
        } else {
          router.push({ name: 'med-card' })
          scheduleInfo.value = null
        }
      } catch (e) {
        scheduleInfo.value = null
        router.push({ name: 'med-card' })
      } finally {
        loading.value = false
      }
    }

    const doctorName = computed(() => {
      if (!scheduleInfo.value?.doctor_name) return null
      return scheduleInfo.value.doctor_name
    })
    const doctorType = computed(() => {
      if (!scheduleInfo.value?.type) return null
      return scheduleInfo.value.type
    })

    const timeAppointment = computed(() => {
      if (!scheduleInfo.value?.date) return null

      return scheduleInfo.value.date.split(' ')[1].slice(0, 5)
    })

    const dateAppointment = computed(() => {
      if (!scheduleInfo.value?.date) return null

      const [year, month, day] = scheduleInfo.value.date.split(' ')[0].split('-')
      const formatMonth = month.startsWith(0) ? month[1] : month

      return `${day} ${dictMonthRod[parseInt(formatMonth) - 1]} ${year} г.`
    })

    const doctorImg = computed(() => {
      if (route.params.resourceId !== 'null') {
        return 'https://www.k31.ru/specialisty/photo/' + route.params.resourceId + '_295x295.jpg'
      }
      return 'https://www.k31.ru/specialisty/photo/0_295x295.jpg'
    })
    const openDicom = async (uid) => {
      try {
        const response = await axios.get('v1/dicom/key/?uid=' + uid, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          dicomUrl.value = `https://dicom.lk.k31.ru/open-study.html?studyUid=${uid}&sessionId=${response.data.id}&sessionKey=${response.data.key}&login=user`
          modalIsOpen.value = true
          window.open(dicomUrl.value, '_blank')
        } else {
          console.log(response.data)
        }
      } catch (e) {
        console.log(e.response.data)
      }
    }

    onActivated(async () => {
      if (getUserId.value) {
        await geScheduleInfo()
      }
    })

    onMounted(async () => {
      if (getUserId.value) {
        await geScheduleInfo()
      }
    })

    return {
      doctorName,
      loading,
      doctorType,
      timeAppointment,
      dateAppointment,
      showErrorToaster,
      scheduleInfo,
      modalIsOpen,
      openDicom,
      dicomUrl,
      doctorImg
    }
  }
}
</script>

<style scoped lang="scss">
.my-schedule {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &__wrapper {
    flex: 1 1 auto;
    margin-bottom: 0;
  }

  &__top {
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  &__back {
    align-self: start;
    justify-self: start;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: translateX(-2px);
    }
  }

  &__title {
    justify-self: center;
  }

  &__middle {
    margin-bottom: 12px;
    display: flex;
    gap: 8px;
    padding-bottom: 12px;
    border-bottom: 1px solid $blue-light;
  }

  &__picture {
    max-width: 45px;

    & img {
      border-radius: 50%;
    }
  }

  &__doc-info {
    max-width: 170px;
    margin-right: auto;
  }

  &__doc-title {
    color: $blue;
    font-size: 14px;
    font-style: normal;
    line-height: 16px;
    margin-bottom: 5px;
  }

  &__doc-desc {
    color: $grey;
    font-size: 12px;
  }

  &__date {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__time {
    align-self: end;
    display: flex;
    gap: 5px;
    align-items: center;

    color: $black;
    font-weight: 700;
  }

  &__clock {
  }

  &__date-full {
    color: $blue;
    font-size: 12px;
  }

  &__subtitle {
    & h5 {
      margin-bottom: 4px;
      line-height: 14px;
    }
  }

  &__bottom {
  }

  &__diagnosis {
    margin-bottom: 4px;
    color: $blue;
    font-size: 14px;
    line-height: 16px;
  }

  &__text {
    margin-bottom: 12px;
    padding-left: 16px;
    color: $black;
    font-size: 12px;
    line-height: 16px;
  }

  &__list {
    margin-bottom: 12px;
    padding-left: 16px;
  }

  &__item {
    color: $black;
    font-size: 12px;
    line-height: 16px;
    list-style-type: decimal;
  }

  &__btns {
  }
}

// ======
.medcard__content-block {
  margin-bottom: 25px;
}

.my-schedule__content {
  padding: 20px;
  border-radius: 6px;
  background: #fff;
}

.medcard__subtitle {
  margin-bottom: 12px;
  color: $blue;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  @include tablets-md {
    font-size: 16px;
  }

  @include phone-xs {
    font-size: 12px;
  }
}

.medcard__main {
  margin-bottom: 10px;
  color: $blue;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */

  @include tablets-md {
    font-size: 14px;
  }

  @include phone-xs {
    font-size: 14px;
  }
}

.medcard__inner-block {
  margin-bottom: 15px;
  margin-left: 10px;
  line-height: 1.7;
}

.medcard__title {
  margin-right: 15px;
}

.medcard__link {
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.medcard__link:hover {
  color: #666;
}

.medcard__links {
  display: flex;
  flex-wrap: wrap;
}

.medcard__link-title {
  color: #666;
  text-decoration: none;
  font-weight: bold;
}
</style>
