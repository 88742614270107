<template>
  <li class="history__item">
    <router-link
        :to="`/my-schedule/${appointmentId}/${resourceId}`"
        class="history__link"
    >
      <div class="history__top">
        <img src="@/assets/uploads/images/home-page/my-appointment/clock.svg" alt="картинка часов">
        <div class="history__time" v-if="timeAppointment">
          {{ timeAppointment }}
        </div>
        <div class="history__date">
          {{ dateAppointment }}
        </div>
        <div class="history__type">
          {{ type }}
        </div>
      </div>
      <div class="history__middle">
        <div class="history__picture" v-if="doctorImg">
          <img :src="doctorImg" :alt="doctorName">
        </div>
        <div class="history__info">
          <p class="history__department">
            {{ doctorTitle }}
          </p>
          <h4 class="history__title">
            {{ doctorName }}
          </h4>
        </div>
      </div>
    </router-link>

    <div class="history__bottom" v-if="filial">
      <img src="@/assets/uploads/images/home-page/my-appointment/marker.svg" alt="знак метки на карте">
      <p class="history__filial">
        Филиал "{{ filial }}"
      </p>
      <!--      <a href="#" class="appointment-list__calendar">-->
      <!--        Записаться снова-->
      <!--      </a>-->
    </div>
  </li>
</template>

<script>
import { useDateStore } from '@/stores/date'
import { computed, toRefs } from 'vue'

export default {
  name: 'AppointmentHistoryListItem',
  props: {
    date: {
      type: String,
      require: true,
      default: null
    },
    type: {
      type: String,
      require: true,
      default: 'Прием'
    },
    calendarLink: {
      type: String,
      require: true,
      default: null
    },
    doctorName: {
      type: String,
      require: false,
      default: null
    },
    doctorTitle: {
      type: String,
      require: true,
      default: null
    },
    filial: {
      type: String,
      require: false,
      default: null
    },
    appointmentId: {
      type: Number,
      require: false,
      default: null
    },
    resourceId: {
      type: Number,
      require: false,
      default: null
    }
  },

  setup (props) {
    const { date, resourceId } = toRefs(props)
    const dateStore = useDateStore()
    const dictMonthRod = dateStore.dictMonthRod

    const timeAppointment = computed(() => {
      if (!date.value) return null

      return date.value.split('T')[1].slice(0, 5)
    })

    const dateAppointment = computed(() => {
      if (!date.value) return null

      const [year, month, day] = date.value.split('T')[0].split('-')
      const formatMonth = month.startsWith(0) ? month[1] : month

      return `${day} ${dictMonthRod[parseInt(formatMonth) - 1]} ${year} г.`
    })

    const doctorImg = computed(() => {
      if (resourceId.value && resourceId.value !== 'null') {
        return 'https://www.k31.ru/specialisty/photo/' + resourceId.value + '_295x295.jpg'
      }
      return 'https://www.k31.ru/specialisty/photo/0_295x295.jpg'
    })

    return {
      timeAppointment,
      dateAppointment,
      doctorImg
    }
  }
}
</script>

<style scoped lang="scss">
.history {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
  color: #7F8DA9;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    padding: 8px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__time {
    margin-right: 10px;
    font-weight: 700;
    color: #222222;
  }

  &__date {
    margin-right: auto;
  }

  &__type {
    padding: 2px 8px;
    background: #002856;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
  }

  &__link {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__picture {
    max-width: 45px;

    & img {
      border-radius: 50%;
    }
  }

  &__info {
  }

  &__department {
    margin-bottom: 4px;
  }

  &__title {
    color: #002856;
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filial {
    margin-right: auto;
  }

  &__calendar {
    text-decoration-line: underline;
    color: #002856;
  }
}
</style>
