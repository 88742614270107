import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'

export async function useAuthUser (phone, code) {
  const userStore = useUserStore()
  const { setUserToken, setUser } = userStore

  try {
    const response = await axios.get('/v1/auth/login', {
      headers: {
        Authorization: `Basic ${btoa(phone + ':' + code)}`,
        'Cache-Control': 'no-cache'
      }
    })
    if (response.data.status === 'ok') {
      setUserToken(response.data.data.token)
      await setUser(response.data.data.token)
      return response
    }
  } catch (err) {
    throw new Error(err)
  }
}
