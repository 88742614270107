<template>
  <section class="my-analyzes">
    <SectionWrapper class="my-analyzes__wrapper">
      <div class="container">
        <div class="my-analyzes__wrapper">
          <div class="my-analyzes__top">
            <router-link
                :to="{ name: 'med-card-analyzes' }"
                class="my-analyzes__back"
            >
              <img
                  src="@/assets/uploads/images/med-card/arrow-left.svg"
                  alt="стрелочка назад"
              />
            </router-link>

            <TheTitle noMargin class="my-analyzes__title">
              <h3>Анализ крови</h3>
            </TheTitle>
          </div>

          <div class="my-analyzes__middle">
            <div class="my-analyzes__picture">
              <img
                  src="@/assets/uploads/images/med-card/blood.jpg"
                  alt="Капля крови"
              />
            </div>
            <div class="my-analyzes__doc-info">
              <h4 class="my-analyzes__doc-title">
                Определение тестостерона общего
              </h4>
              <p class="my-analyzes__doc-desc">
                Биоматериал: кровь цельная
              </p>
              <p class="my-analyzes__doc-desc">
                Локус: вена
              </p>
              <h5 class="my-analyzes__doc-title">
                Исполнитель: Петрова А.Б.
              </h5>
            </div>
            <div class="my-analyzes__date">
              <div class="my-analyzes__time">
                <img
                    src="@/assets/uploads/images/home-page/my-appointment/clock.svg"
                    alt="картинка часов"
                />
                <p class="my-analyzes__clock">14:50</p>
              </div>
              <p class="my-analyzes__date-full">10 апреля 2023 г.</p>
            </div>
          </div>

          <AnalyzesForm/>

          <TheTitle class="my-analyzes__subtitle" no-margin font-size="12px">
            <h5>Рекомендации:</h5>
          </TheTitle>

          <p class="my-analyzes__text">
            Хроническое, раннее выявленное, сопутствующее Синдром
            преждевременного возбуждения желудочков,(Синдром
            Вольфа-Паркинсона-Уайта)
          </p>

          <div class="my-analyzes__btns">
            <CustomButton type="button" tag="button" @click="$toast.error('Функционал в разработке')">
              <template #img>
                <img
                    src="@/assets/uploads/images/med-card/download.svg"
                    alt="знак скачивания"
                />
              </template>
              <template #default>
                <p>Сохранить на своем устройстве</p>
              </template>
            </CustomButton>

            <CustomButton white type="button" tag="button" @click="$toast.error('Функционал в разработке')">
              <template #img>
                <img
                    src="@/assets/uploads/images/med-card/refresh.svg"
                    alt="знак скачивания"
                />
              </template>
              <template #default>
                <p>сдать анализ снова</p>
              </template>
            </CustomButton>
          </div>
        </div>
      </div>
    </SectionWrapper>
  </section>
</template>

<script>
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import TheTitle from '@/components/ui/typography/TheTitle.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import AnalyzesForm from '@/components/pages/med-card/AnalyzesForm.vue'

export default {
  name: 'MyAnalyzesView',
  components: { AnalyzesForm, CustomButton, TheTitle, SectionWrapper }
}
</script>

<style scoped lang="scss">
.my-analyzes {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &__wrapper {
    flex: 1 1 auto;
    margin-bottom: 0;
  }

  &__top {
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  &__back {
    align-self: start;
    justify-self: start;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: translateX(-2px);
    }
  }

  &__title {
    justify-self: center;
  }

  &__middle {
    margin-bottom: 12px;
    display: flex;
    gap: 8px;
    padding-bottom: 12px;
    border-bottom: 1px solid $blue-light;
  }

  &__picture {
    max-width: 45px;

    & img {
      border-radius: 50%;
    }
  }

  &__doc-info {
    max-width: 170px;
    margin-right: auto;
  }

  &__doc-title {
    color: $blue;
    font-size: 14px;
    font-style: normal;
    line-height: 16px;
    margin-bottom: 5px;
  }

  &__doc-desc {
    color: $grey;
    font-size: 12px;
  }

  &__date {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__time {
    align-self: end;
    display: flex;
    gap: 5px;
    align-items: center;

    color: $black;
    font-weight: 700;
  }

  &__clock {
  }

  &__date-full {
    color: $blue;
    font-size: 12px;
  }

  &__text {
    margin-bottom: 12px;
    padding-left: 16px;
    color: $black;
    font-size: 12px;
    line-height: 16px;
  }

  &__subtitle {
    & h5 {
      margin-bottom: 4px;
      line-height: 14px;
    }
  }
}
</style>
