<template>
  <ArrowTitle title="Медицинская карта"></ArrowTitle>
  <SectionWrapper stretched>
    <TheLoader v-if="loading"/>

    <TheText v-if="medTabs.length === 0 && !error && !loading">
      У вас пока нет Истории приемов / Анализов / Исследований
    </TheText>
    <div v-if="medTabs.length !== 0">
      <TheTabs :tabs="medTabs" @changeTab="changeTab" :currentTab="currentTab"/>
      <div class="appointment__wrapper">
        <ul class="appointment-list" v-if="currentTab.title !== 'Анализы'">
          <AppointmentHistoryListItem
              v-for="appointment in currentTab.items"
              :key="appointment.id"
              :date="appointment.date"
              :doctorName="appointment.doctor_name"
              :doctorTitle="appointment.title"
              :filial="appointment.clinic_name"
              :appointmentId="appointment.id"
              :resourceId="appointment.resource_id"
              :type="currentTab.title"
          />
        </ul>

        <ul class="appointment-list" v-else>
          <AnalyzesHistoryItem
              v-for="item in currentTab.items"
              :key="item.file_id"
              :date="item.date"
              :title="item.title"
              :loadLink="item.url"
              :fileName="item.file_name"
              :description="item.description"
              type="Анализы"
          />
        </ul>
      </div>
    </div>

    <p v-if="error" class="error help-block">
      <TheText>
        У вас пока нет истории приемов, запишитесь к врачу.
      </TheText>
      <CustomButton white>
        <router-link to="/online-appointment">
          выбрать специалиста
        </router-link>
      </CustomButton>
    </p>

  </SectionWrapper>
</template>

<script>
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import TheTabs from '@/components/pages/TheTabs.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useUserStore } from '@/stores/UserStore'
import axios from 'axios'
import AppointmentHistoryListItem from '@/components/pages/med-card/AppointmentHistoryListItem.vue'
import TheText from '@/components/ui/typography/TheText.vue'
import AnalyzesHistoryItem from '@/components/pages/med-card/AnalyzesHistoryItem.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'

export default {
  name: 'MedCardView',
  components: {
    CustomButton,
    AnalyzesHistoryItem,
    TheText,
    AppointmentHistoryListItem,
    TheLoader,
    TheTabs,
    SectionWrapper,
    ArrowTitle
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup () {
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const loading = ref(false)
    const error = ref(false)
    const currentTab = ref([])
    const medTabs = ref([])

    const changeTab = (tab) => {
      currentTab.value = tab
    }
    const getMedCardInfo = (path) => {
      return axios(path, {
        headers: {
          Authorization: `Bearer ${userToken.value}`
        }
      })
    }
    const getTabs = async (id) => {
      try {
        loading.value = true
        medTabs.value = []
        error.value = false

        const response = await Promise.all(
          [
            getMedCardInfo(`/v2/element/patient-history/group/?user_id=${id}`),
            getMedCardInfo(`/v2/element/patient-history/files/?user_id=${id}`)
          ]
        )

        if (response[0].data.status !== 'ok') {
          currentTab.value = null
          medTabs.value = []
          error.value = true
          return
        }

        if (response[0].data.status === 'ok') {
          medTabs.value.push(...response[0].data.data)

          // Прием на первое место
          medTabs.value.sort((tabA, tabB) => {
            if (tabA.title === 'Приемы') return -1
            if (tabB.title === 'Приемы') return 1
            return tabA.title.localeCompare(tabB.title)
          })
        }
        if (response[1].data.status === 'ok') {
          medTabs.value.push({
            title: 'Анализы',
            items: [...response[1].data.data]
          })
        }

        if (medTabs.value.length !== 0) {
          // фильтрация по дате
          medTabs.value.forEach((tab) => {
            tab.items.sort((scheduleA, scheduleB) => new Date(scheduleA.date) > new Date(scheduleB.date) ? -1 : 1)
          })
        }

        currentTab.value = medTabs.value[0]
        error.value = false
      } catch (err) {
        currentTab.value = null
        medTabs.value = []
        error.value = true
      } finally {
        loading.value = false
      }
    }

    watch(() => getUserId.value, async () => {
      if (getUserId.value) {
        await getTabs(getUserId.value)
      }
    },
    {
      immediate: true
    })

    // onMounted(async () => {
    //   if (getUserId.value) {
    //     await getTabs(getUserId.value)
    //   }
    // })
    //
    // onActivated(async () => {
    //   if (getUserId.value) {
    //     await getTabs(getUserId.value)
    //   }
    // })

    return {
      loading,
      changeTab,
      currentTab,
      medTabs,
      error
    }
  }
}
</script>

<style scoped lang="scss">
.my-appointment__text {
  margin-bottom: 10px;
}

.my-appointment__bottom {
  margin-bottom: 30px;

  & span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #f1842c;
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }
}

.appointment-list {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
  color: #7f8da9;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__time {
    margin-right: 10px;
    font-weight: 700;
    color: #222222;
  }

  &__date {
    margin-right: auto;
  }

  &__type {
    padding: 2px 8px;
    background: #002856;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__picture {
  }

  &__info {
  }

  &__department {
    margin-bottom: 4px;
  }

  &__title {
    color: #002856;
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filial {
    margin-right: auto;
  }

  &__calendar {
    text-decoration-line: underline;
    color: #002856;
  }
}
</style>
